import { IonContent, IonHeader, IonList, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import BulletinListItem from "components/BulletinListitem/BulletinListItem";
import { AppContext } from "domain/AppContextProvider";
import { Bulletin } from "domain/Interfaces";
import React, { useContext } from "react";
import { getBookClassBg } from "utils/Utils";
import "./BulletinsTab.css";

const BulletinsTab: React.FC = () => {
  const { appContext } = useContext(AppContext);
  const bookID = appContext.bookID;

  const sortBulletinsByDate = (attempts: Bulletin[]) => {
    return attempts.sort((a, b) => (a.date > b.date ? -1 : a.date < b.date ? 1 : 0));
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className={getBookClassBg(bookID)}>
          <IonTitle>Bulletins</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent scrollY={false}>
        <div className="contentWrapper">
          <div style={{ display: "flex", justifyContent: "center", paddingRight: "10px", paddingLeft: "10px" }}>
            <IonList id="bulletinsListContainer">
              {sortBulletinsByDate([...appContext.bulletins]).map((mod, index) => (
                <BulletinListItem
                  key={index}
                  bulletin={mod}
                  isLastItem={index === appContext.bulletins.length - 1}
                  index={index}
                />
              ))}
            </IonList>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(BulletinsTab);
