import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonToast,
} from "@ionic/react";
import AttemptAnswerItem from "components/AttemptAnswerItem/AttemptAnswerItem";
import CustomBackButton from "components/CustomBackButton/CustomBackButton";
import GenerateCertificateButton from "components/GenerateCertificateButton/GenerateCertificateButton";
import { AppContext } from "domain/AppContextProvider";
import { useAppSelector } from "hooks/customReduxHooks";
import { useContext } from "react";
import { useLocation, useParams } from "react-router";
import {
  getBookClassBg,
  getCurrentBookFromBookID,
  getLatestPassedQuizAttemptForModule,
  getQuizAttemptsForModule,
} from "utils/Utils";
import "./QuizPreviousResultsAnswers.css";

const QuizPreviousResultsAnswers: React.FC<{}> = () => {
  const location = useLocation();
  const params = useParams<{ moduleNumber: string; attemptIndex: string }>();

  const { appContext } = useContext(AppContext);
  const { allQuizAttempts } = useAppSelector(state => state);

  const [presentToast] = useIonToast();

  const books = appContext.books;
  const bookID = appContext.bookID;
  const currentBook = getCurrentBookFromBookID(books, bookID);
  const currentModule = currentBook.modules.find(mod => mod.number === parseInt(params.moduleNumber))!;
  const filteredQuizAttempts = getQuizAttemptsForModule(bookID, currentModule.number, allQuizAttempts.attempts);
  const currentQuizAttempt = filteredQuizAttempts[parseInt(params.attemptIndex)];
  const hasPassedAttempt = filteredQuizAttempts.some(attempt => attempt.hasPassed);
  const latestPassedAttempt = getLatestPassedQuizAttemptForModule(filteredQuizAttempts);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className={getBookClassBg(bookID)}>
          <IonTitle>Quiz Results</IonTitle>
          <IonButtons slot="start">
            <CustomBackButton url={location.pathname} />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent scrollY={false}>
        <div className="contentWrapper">
          <div id="quizResultsAnswersContainer">
            <h2>Module {currentModule?.number}</h2>
            <h1>{currentModule?.name}</h1>
            <div className="basicDivider" />
            <h3 id="resultsTitle">Your results</h3>
            <div id="score">
              <span>
                <span
                  id="totScore"
                  className={currentQuizAttempt.hasPassed ? "passScoreColour" : "inProgressScoreColour"}
                >
                  {currentQuizAttempt.score}
                </span>{" "}
                / {currentQuizAttempt.maxScore}
              </span>
            </div>
            {hasPassedAttempt && (
              <GenerateCertificateButton
                user={appContext.user!}
                module={currentModule}
                book={currentBook}
                latestPassedAttempt={latestPassedAttempt!}
                handleError={() =>
                  presentToast({
                    message: "Failed to generate certificate, check your network connection.",
                    mode: "ios",
                    duration: 2500,
                  })
                }
              />
            )}
            <h3 id="answersTitle">Your answers</h3>
            {currentQuizAttempt.questionsAnswered.map((question, index) => {
              return (
                <AttemptAnswerItem
                  key={index}
                  question={question}
                  currentModule={currentModule!}
                  isLastItem={index === currentQuizAttempt.questionsAnswered.length - 1}
                />
              );
            })}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default QuizPreviousResultsAnswers;
