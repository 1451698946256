import { configureStore } from "@reduxjs/toolkit";
import fontSizePercentageReducer from "features/fontSizePercentage/fontSizePercentageSlice";
import quizAttemptsReducer from "features/quizAttempts/quizAttemptsSlice";
import bookmarksReducer from "features/bookmarks/bookmarksSlice";
import progressReducer from "features/progress/progressSlice";

// infer the 'RootState' and 'AppDispatch' types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// inferred type
export type AppDispatch = typeof store.dispatch;

export const store = configureStore({
  reducer: {
    fontSizePercentage: fontSizePercentageReducer,
    allQuizAttempts: quizAttemptsReducer,
    bookmarks: bookmarksReducer,
    progress: progressReducer,
  },
});
