import { IonButton, useIonLoading, useIonModal, useIonToast } from "@ionic/react";
import { AppContext } from "domain/AppContextProvider";
import { useContext, useState } from "react";
import { ServerService } from "services/ServerService";
import "./RequestDeleteButton.css";

const ConfirmationModal: React.FC<{ onCancel: () => void; onConfirm: () => void }> = ({ onCancel, onConfirm }) => {
  return (
    <div style={{ color: "black", fontFamily: "noto-reg" }}>
      <div className="ta-center p-14" style={{ fontWeight: "bold" }}>
        Are you sure you want to delete your account?
      </div>
      <div className="flex flex-d-row">
        <div onClick={onCancel} className="flex-one ta-center p-12 bt br" style={{ cursor: "pointer" }}>
          Cancel
        </div>
        <div
          onClick={onConfirm}
          className="flex-one ta-center p-12 bt bl"
          style={{ cursor: "pointer", color: "red", fontWeight: "bold" }}
        >
          Yes
        </div>
      </div>
    </div>
  );
};

const RequestDeleteButton: React.FC<{ addMargin: boolean }> = ({ addMargin }) => {
  const { appContext } = useContext(AppContext);

  const [presentToast] = useIonToast();
  const [presentOverlay, dismissOverlay] = useIonLoading();
  const [presentConfirmationModal, dismissConfirmationModal] = useIonModal(ConfirmationModal, {
    onCancel: () => dismissConfirmationModal(),
    onConfirm: () => handleAccountDeletion(),
  });

  const handleAccountDeletion = async () => {
    presentOverlay({ message: "Requesting Account Deletion..." });
    const success = await ServerService.requestAccountDeletion(appContext.user!);
    dismissOverlay();
    if (!success) {
      presentToast({
        message: "Failed to request account deletion",
        mode: "ios",
        duration: 2500,
      });
    }

    dismissConfirmationModal();
  };

  return (
    <>
      <IonButton
        className={`c-red br-2 ba-none ${addMargin ? "mr-20 ml-20" : ""}`}
        fill="outline"
        style={{ "--border-color": "red" }}
        onClick={() => presentConfirmationModal({ cssClass: "confirmationModal", animated: false })}
      >
        Request account deletion
      </IonButton>
    </>
  );
};

export default RequestDeleteButton;
