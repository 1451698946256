import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Progress, User } from "domain/Interfaces";
import { LocalStorageHelper } from "domain/LocalStorageHelper";

interface ProgressState {
  progress: Progress;
}

const initialState: ProgressState = {
  progress: {
    rhFinishedChapters: [],
    ptFinishedChapters: [],
  },
};

export const progressSlice = createSlice({
  name: "progress",
  initialState: initialState,
  reducers: {
    setProgress: (state, action: PayloadAction<{ progress: Progress; user: User }>) => {
      state.progress = action.payload.progress;

      LocalStorageHelper.saveProgress(state.progress, action.payload.user.code);
    },
  },
});

export const { setProgress } = progressSlice.actions;

export default progressSlice.reducer;
