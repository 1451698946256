import { IonIcon } from "@ionic/react";
import { AppContext } from "domain/AppContextProvider";
import { Module, QuizAttempt } from "domain/Interfaces";
import { useContext } from "react";
import { formatDateTime, getCurrentBookFromBookID } from "utils/Utils";
import "./PreviousResultItem.css";

const PreviousResultItem: React.FC<{
  handleClick: () => void;
  quizAttempt: QuizAttempt;
  module: Module;
  isLastItem: boolean;
}> = ({ handleClick, quizAttempt, module, isLastItem }) => {
  const { appContext } = useContext(AppContext);
  // const { allQuizAttempts } = useAppSelector(state => state);

  const books = appContext.books;
  const bookID = appContext.bookID;
  // const currentBook = getCurrentBookFromBookID(books, bookID);
  // const currentModule = currentBook.modules.find(mod => mod.number === module.number)!;

  return (
    <>
      <div id="previousResultItemContainer" onClick={handleClick}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span id="dateTime">{formatDateTime(quizAttempt.dateTimeStarted)}</span>
          <span id="scoreLabel">
            <span id="totalScore" className={quizAttempt.hasPassed ? "passScoreColour" : "inProgressScoreColour"}>
              {quizAttempt.score}
            </span>{" "}
            / {quizAttempt.maxScore}
          </span>
        </div>
        <IonIcon id="previousResultItemIcon" icon={"./assets/svgs/Button_Disclosure_Right.svg"} />
      </div>
      {isLastItem ? null : <div className="basicDivider" />}
    </>
  );
};

export default PreviousResultItem;
