import React, { useContext } from "react";
import { IonContent, IonHeader, IonList, IonPage, IonSpinner, IonTitle, IonToolbar } from "@ionic/react";
import { getBookClassBg, getCurrentBookFromBookID } from "utils/Utils";
import { useHistory } from "react-router";
import ModuleListItem from "components/ModuleListItem/ModuleListItem";
import "./ModulesTab.css";
import { AppContext } from "domain/AppContextProvider";

const ModulesTab: React.FC<{}> = () => {
  const { appContext } = useContext(AppContext);

  const books = appContext.books;
  const bookID = appContext.bookID;
  const currentBook = getCurrentBookFromBookID(books, bookID);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className={getBookClassBg(bookID)}>
          <IonTitle>Modules</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent scrollY={false}>
        <div className="contentWrapper">
          <div style={{ display: "flex", justifyContent: "center", paddingRight: "10px", paddingLeft: "10px" }}>
            <IonList id="moduleListContainer">
              {currentBook.modules.map((mod, index) => (
                <ModuleListItem
                  key={index}
                  module={mod}
                  isLastItem={index === currentBook.modules.length - 1}
                  link={`/modules/module-${mod.number}`}
                />
              ))}
            </IonList>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(ModulesTab);
