import { Subscriptions, Transaction } from "capacitor-subscriptions";
import { AppEntitlementsResponse, Subscription } from "domain/Interfaces";
import { mapGoogleSub } from "utils/Utils";

export namespace IapService {
  export const getEntitlmentsFromApple = async (): Promise<AppEntitlementsResponse> => {
    let subs: Subscription[] = [];
    let success = true;

    try {
      // Calling getCurrentEntitlements() will return an array of subscription transactions which are still ACTIVE.
      const entitlementsResponse = await Subscriptions.getCurrentEntitlements();
      console.log("entitlementsResponse for Apple: ", entitlementsResponse.data);
      /**
       * response codes and matching messages:
       * -1: Incompatible with web
       * 0: Successfully found all entitlements across all product types
       * 1: No entitlements were found
       * 2: Unknown problem trying to retrieve entitlements
       */
      if (entitlementsResponse.responseCode === 2) {
        success = false;
      } else {
        if (entitlementsResponse.responseCode === 0) {
          const data = entitlementsResponse.data as any;
          if (data) {
            const keys = Object.keys(data);
            keys.forEach(key => {
              subs.push({
                endDate: data[key].expiryDate,
                type: data[key].productIdentifier.includes("passenger") ? "pt" : "rh",
                productId: data[key].productIdentifier,
                transactionId: data[key].transactionId,
                startDate: data[key].originalStartDate,
              });
            });
          }
        }
      }
    } catch (error) {
      console.log("Failed to get in-app subs from Apple");
      success = false;
    }

    return { success, subs };
  };

  export const getEntitlmentsFromGoogle = async (): Promise<AppEntitlementsResponse> => {
    let subs: Subscription[] = [];
    let success = true;

    try {
      // Calling getCurrentEntitlements() will return an array of subscription transactions which are still ACTIVE.
      const entitlementsResponse = await Subscriptions.getCurrentEntitlements();
      console.log("entitlementsResponse for Google: ", entitlementsResponse.data);
      /**
       * response codes and matching messages:
       * -1: Incompatible with web
       * 0: Successfully found all entitlements across all product types
       * 1: No entitlements were found
       * 2: Unknown problem trying to retrieve entitlements
       */
      if (entitlementsResponse.responseCode === 2) {
        success = false;
      } else {
        if (entitlementsResponse.responseCode === 0 && entitlementsResponse.data) {
          const res = JSON.parse(entitlementsResponse.data.toString());
          res.forEach((s: any) => {
            subs.push(mapGoogleSub(s));
          });
        }
      }
    } catch (error) {
      console.log("Failed to get in-app subs from Google");
      success = false;
    }

    return { success, subs };
  };
}
