import { useContext, useState } from "react";
import { TextFieldTypes } from "@ionic/core";
import { IonButton, IonContent, IonIcon, IonInput, IonPage, IonRouterLink, IonSpinner } from "@ionic/react";
import { AppContext } from "domain/AppContextProvider";
import { ServerService } from "services/ServerService";
import { User } from "domain/Interfaces";
import { validateSubscriptionForUser } from "utils/Utils";
import { Capacitor } from "@capacitor/core";
import { LocalStorageHelper } from "domain/LocalStorageHelper";
import useLoadAppData from "hooks/useLoadAppData";
import LoginTermsAndConditions from "components/LoginTermsAndConditions/LoginTermsAndConditions";
import "./Login.css";

const Login: React.FC<{}> = ({}) => {
  const { setAppContext } = useContext(AppContext);

  const loadAppData = useLoadAppData();

  const [loggingIn, setLoggingIn] = useState(false);
  const [email, setEmail] = useState(""); // Test email = andy+eos@aimermedia.com
  const [password, setPassword] = useState(""); // Test password = Test123
  const [passwordInputType, setpasswordInputType] = useState<TextFieldTypes>("password");
  const [error, setError] = useState<{ show: boolean; message?: string }>({ show: false });

  const inputsIncomplete = email === "" || password === "";

  const handleLogin = async () => {
    setError({ show: false });
    setLoggingIn(true);

    const res = await ServerService.login(email, password);
    if (res.success) {
      let user: User = {
        name: res.user?.name!,
        email: email,
        jwt: res.user?.jwt!,
        code: res.user?.code!,
        ptSub: null,
        rhSub: null,
      };

      user = await validateSubscriptionForUser(user);

      const bookID = await LocalStorageHelper.getBookSelection(user);

      if (Capacitor.getPlatform() === "web" && !user.ptSub && !user.rhSub) {
        setError({
          show: true,
          message: "No subscription found. You cannot access the app's content without a valid subscription.",
        });
        setLoggingIn(false);
      } else {
        const appData = await loadAppData(user);
        setAppContext(prev => ({
          ...prev,
          user: user,
          books: appData.bookData,
          bookID: bookID,
          bulletins: appData.bulletins,
        }));
      }
    } else {
      setError({ show: true, message: res.message });
      setLoggingIn(false);
    }
  };

  return (
    <IonPage>
      <IonContent scrollY={false} id="loginContent">
        <div className="contentWrapper" style={{ display: "flex", flexDirection: "column" }}>
          <div
            id="loginHeaderImgContainer"
            style={{ background: `url("./assets/images/Login_Background.png") no-repeat center` }}
          >
            <div id="loginHeaderImgOverlay">
              <div>Welcome to the Transport Managers Guide</div>
              <div style={{ fontSize: "15px", marginTop: "8px" }}>Powered by EOS Training</div>
            </div>
          </div>
          <div id="loginContentContainer">
            <div>
              <form>
                <div className="loginTitle">Log in</div>
                <div className="inputLabel">Email</div>
                <IonInput
                  className="userInput"
                  disabled={loggingIn}
                  type="email"
                  onIonChange={e => setEmail(e.detail.value!)}
                />
                <div className="inputLabel">Password</div>
                <div id="passwordInputContainer">
                  <IonInput
                    disabled={loggingIn}
                    type={passwordInputType}
                    onIonChange={e => setPassword(e.detail.value!)}
                  />
                  <IonButton
                    fill="clear"
                    onClick={() => setpasswordInputType(prev => (prev === "text" ? "password" : "text"))}
                  >
                    {passwordInputType === "password" ? "Show" : "Hide"}
                  </IonButton>
                </div>
                {error.show && (
                  <div id="loginErrorMsg">
                    <IonIcon id="loginErrorMsgIcon" icon={"./assets/svgs/Button_Error.svg"} />
                    {error.message}
                  </div>
                )}
              </form>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <IonButton
                  className="loginBtn"
                  disabled={inputsIncomplete}
                  style={{ "--opacity": inputsIncomplete ? "0.4" : "1" }}
                  onClick={handleLogin}
                >
                  {loggingIn ? <IonSpinner /> : "Log in"}
                </IonButton>
              </div>
              <div id="passwordResetLink">
                <a style={{ color: "#00535d" }} href="https://www.classprofessional.co.uk/app-reset" target={"_blank"}>
                  Forgot your password?
                </a>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
              <IonRouterLink routerLink="/register" id="registerLink">
                Register here and explore subscription options/prices
              </IonRouterLink>
              <LoginTermsAndConditions />
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Login;
