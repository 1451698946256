import { IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import QuizBubble from "components/QuizBubble/QuizBubble";
import QuizResultsBubble from "components/QuizResultsBubble/QuizResultsBubble";
import { AppContext } from "domain/AppContextProvider";
import { useAppDispatch, useAppSelector } from "hooks/customReduxHooks";
import React, { useContext } from "react";
import { useHistory, useLocation } from "react-router";
import { getBookClassBg, getCurrentBookFromBookID } from "utils/Utils";
import "./QuizzesTab.css";

const QuizzesTab: React.FC = () => {
  const { appContext } = useContext(AppContext);
  const bookID = appContext.bookID;

  const books = appContext.books;

  const currentBook = getCurrentBookFromBookID(books, bookID);

  const history = useHistory();
  const location = useLocation();

  const modulesWithQuizes = currentBook.modules.filter(mod => mod.quizQuestions.length > 0);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className={getBookClassBg(bookID)}>
          <IonTitle>Quizzes</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent scrollY={false}>
        <div className="contentWrapper">
          <div id="quizzesTabContentContainer">
            <QuizResultsBubble modulesWithQuizes={modulesWithQuizes} />
            {modulesWithQuizes.map((mod, index) => {
              return (
                <QuizBubble
                  key={index}
                  module={mod}
                  handleClick={() => history.push(`${location.pathname}/overview-module-${mod.number}`)}
                />
              );
            })}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default React.memo(QuizzesTab);
