import { IonItem } from "@ionic/react";
import { Bulletin } from "domain/Interfaces";
import { useLocation } from "react-router";
import { formatDate } from "utils/Utils";
import "./BulletinListItem.css";

const BulletinListItem: React.FC<{ bulletin: Bulletin; isLastItem: boolean; index: number }> = ({
  bulletin,
  isLastItem,
  index,
}) => {
  const location = useLocation();

  return (
    <IonItem
      lines={isLastItem ? "none" : "full"}
      id="bulletinListItem"
      routerLink={`${location.pathname}/bulletin-${bulletin.id}`}
    >
      <div>
        <h2>{formatDate(bulletin.date)}</h2>
        <h1>{bulletin.title}</h1>
      </div>
    </IonItem>
  );
};

export default BulletinListItem;
