import { IonButton, IonContent, IonLabel, IonPopover, IonSegment, IonSegmentButton } from "@ionic/react";
import { AppContext } from "domain/AppContextProvider";
import { ThemeSelectionOption } from "domain/Interfaces";
import { LocalStorageHelper } from "domain/LocalStorageHelper";
import { useAppDispatch, useAppSelector } from "hooks/customReduxHooks";
import { Ref, useContext } from "react";
import "./TextControlsPopover.css";

const TextControlsPopover: React.FC<{
  isOpen: boolean;
  popoverRef: Ref<HTMLIonPopoverElement>;
  handleFontSizeIncrease: () => void;
  handleFontSizeDecrease: () => void;
  handleDismiss: () => void;
}> = ({ isOpen, popoverRef, handleFontSizeIncrease, handleFontSizeDecrease, handleDismiss }) => {
  const dispatch = useAppDispatch();
  const { fontSizePercentage } = useAppSelector(state => state);
  const { appContext, setAppContext } = useContext(AppContext);

  const handleThemeChange = (theme: string) => {
    switch (theme) {
      case "Dark":
        setAppContext(prev => ({ ...prev, theme: ThemeSelectionOption.Dark }));
        LocalStorageHelper.setTheme(ThemeSelectionOption.Dark);
        document.body.classList.add("dark");
        break;

      case "Light":
        setAppContext(prev => ({ ...prev, theme: ThemeSelectionOption.Light }));
        LocalStorageHelper.setTheme(ThemeSelectionOption.Light);
        document.body.classList.remove("dark");
        break;

      case "Automatic":
        setAppContext(prev => ({ ...prev, theme: ThemeSelectionOption.Auto }));
        LocalStorageHelper.setTheme(ThemeSelectionOption.Auto);
        const prefersDark = window.matchMedia("(prefers-color-scheme: dark)");
        prefersDark.matches ? document.body.classList.add("dark") : document.body.classList.remove("dark");
        break;
    }
  };

  return (
    <IonPopover id="textControlsPopover" mode="ios" ref={popoverRef} isOpen={isOpen} onDidDismiss={handleDismiss}>
      <IonContent scrollY={false}>
        <div id="themeSegmentContainer">
          <IonSegment value={appContext.theme} onIonChange={e => handleThemeChange(e.detail.value!)}>
            {Object.values(ThemeSelectionOption).map((theme, index) => (
              <IonSegmentButton value={theme} key={index}>
                <IonLabel>{theme}</IonLabel>
              </IonSegmentButton>
            ))}
          </IonSegment>
        </div>
        <div />
        <div>
          <IonButton fill="clear" id="textSizeDecrease" onClick={handleFontSizeDecrease}>
            Aa
          </IonButton>
          <span id="textSizePercentage">{`${fontSizePercentage.percentage}%`}</span>
          <IonButton fill="clear" id="textSizeIncrease" onClick={handleFontSizeIncrease}>
            Aa
          </IonButton>
        </div>
      </IonContent>
    </IonPopover>
  );
};

export default TextControlsPopover;
