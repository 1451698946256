import { AppContext } from "domain/AppContextProvider";
import { IonIcon, IonPage } from "@ionic/react";
import { Module } from "domain/Interfaces";
import "./AttemptAnswerItem.css";

const AttemptAnswerItem: React.FC<{
  question: {
    questionNumber: number;
    questionText: string;
    questionType: string;
    caseStudyAnswer: {
      text: string;
      score: number;
    };
    multiChoiceAnswers: {
      text: string;
      isCorrect: boolean;
    }[];
  };
  currentModule: Module;
  isLastItem: boolean;
}> = ({ question, currentModule, isLastItem }) => {
  return (
    <>
      <div id="attemptAnswerContainer">
        <div id="questionNumber">Question {question.questionNumber}</div>
        <p id="questionText">{question.questionText}</p>
        <div id="outerAnswerContainer">
          <div id="answerTitle">Answer</div>
          {question.questionType === "Case Study" ? (
            <>
              <div
                style={{
                  marginTop: "8px",
                  marginBottom: "4px",
                  fontFamily: "noto-reg",
                  fontSize: "17px",
                  color: "var(--primary-accent)",
                }}
              >
                {question.caseStudyAnswer.text}
              </div>
              {/* <div>{question.caseStudyAnswer.score}</div> */}
            </>
          ) : (
            question.multiChoiceAnswers.map((a, index) => {
              return (
                <div id="answerContainer" key={index}>
                  <p id="answerText">{a.text}</p>
                  <IonIcon
                    id="attemptAnswerIcon"
                    className={a.isCorrect ? "filterTick" : "filterCross"}
                    icon={
                      a.isCorrect ? "./assets/svgs/Button_Answer_Tick.svg" : "./assets/svgs/Button_Answer_Cross.svg"
                    }
                  />
                </div>
              );
            })
          )}
        </div>
      </div>
      {isLastItem ? null : <div className="basicDivider" />}
    </>
  );
};

export default AttemptAnswerItem;
