import { IonIcon, IonItem, IonLabel } from "@ionic/react";
import { AppContext } from "domain/AppContextProvider";
import { Chapter } from "domain/Interfaces";
import { useAppSelector } from "hooks/customReduxHooks";
import { useContext } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router";
import { checkIfChapterIsFinished, getCurrentBookFromBookID } from "utils/Utils";
import "./ChapterListItem.css";

const ChapterListItem: React.FC<{ chapter: Chapter; handleClick: () => void; moduleNumber: number }> = ({
  chapter,
  handleClick,
  moduleNumber,
}) => {
  const { bookmarks, progress } = useAppSelector(state => state);
  const { appContext } = useContext(AppContext);

  const books = appContext.books;
  const bookID = appContext.bookID;

  const isChapterBookmarked =
    bookmarks.value.findIndex(
      b => b.chapterNumber === chapter.number && b.moduleNumber === moduleNumber && b.bookId === bookID
    ) !== -1;

  return (
    <IonItem id="chapterListItem" detail onClick={handleClick}>
      <IonIcon
        style={{
          visibility: isChapterBookmarked ? "visible" : "hidden",
        }}
        id="bookIcon"
        icon={"./assets/svgs/Button_Bookmark_Active.svg"}
        slot="start"
      />
      <IonLabel>{chapter.number !== 0 ? `${chapter.number}. ${chapter.name}` : `${chapter.name}`}</IonLabel>
      {checkIfChapterIsFinished(bookID, progress.progress, moduleNumber, chapter.number) && (
        <IonIcon
          style={{ marginRight: "14px" }}
          id="tickIcon"
          icon={"./assets/svgs/Button_Answer_Tick.svg"}
          slot="end"
        />
      )}
    </IonItem>
  );
};

export default ChapterListItem;
