import { IonButton, IonButtons, IonContent, IonHeader, IonModal, IonTitle, IonToolbar } from "@ionic/react";
import BookmarksList from "components/BookmarksList/BookmarksList";
import { AppContext } from "domain/AppContextProvider";
import { useContext } from "react";
import { getBookClassBg } from "utils/Utils";
import "./BookmarksModal.css";

const BookmarksModal: React.FC<{ isOpen: boolean; handleClose: () => void }> = ({ isOpen, handleClose }) => {
  const { appContext } = useContext(AppContext);
  const bookID = appContext.bookID;

  return (
    <IonModal handle={false} className="bookmarksModal" isOpen={isOpen} onWillDismiss={handleClose}>
      <IonHeader>
        <IonToolbar id="bookmarksModalToolbar" className={getBookClassBg(bookID)}>
          <IonButtons slot="start">
            <IonButton style={{ visibility: "hidden" }}>Close</IonButton>
          </IonButtons>
          <IonTitle class="ion-text-center">Bookmarks</IonTitle>
          <IonButtons slot="end">
            <IonButton id="modalClose" onClick={handleClose}>
              Close
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="modalContent">
          <BookmarksList showAll={true} closeModalHandler={handleClose} />
        </div>
      </IonContent>
    </IonModal>
  );
};

export default BookmarksModal;
