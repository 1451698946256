import { AppContext } from "domain/AppContextProvider";
import { Module } from "domain/Interfaces";
import { useAppSelector } from "hooks/customReduxHooks";
import { useContext, useMemo } from "react";
import { getCurrentBookFromBookID, getQuizAttemptsForBook, getQuizAttemptsForModule } from "utils/Utils";
import "./QuizResultsBubble.css";

const ResultsSubContainer: React.FC<{ flex: string; imgSrc: string; count: number; text: string }> = ({
  flex,
  imgSrc,
  count,
  text,
}) => {
  return (
    <div id="resultsSubContainer" style={{ flex: flex }}>
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <img id="resultsSubImg" src={imgSrc} />
        <div id="resultsSubText">{text}</div>
      </div>
      <div id="resultsSubCount">{count}</div>
    </div>
  );
};

const QuizResultsBubble: React.FC<{ modulesWithQuizes: Module[] }> = ({ modulesWithQuizes }) => {
  const { allQuizAttempts } = useAppSelector(state => state);
  const { appContext } = useContext(AppContext);

  const books = appContext.books;
  const bookID = appContext.bookID;
  const currentBook = getCurrentBookFromBookID(books, bookID);
  const filteredQuizAttempts = getQuizAttemptsForBook(bookID, allQuizAttempts.attempts);

  const notStartedMods = useMemo(() => {
    let res = 0;
    modulesWithQuizes.forEach(mod => {
      const filteredAttempts = getQuizAttemptsForModule(bookID, mod.number, allQuizAttempts.attempts);
      if (filteredAttempts.length === 0) res += 1;
    });
    return res;
  }, [modulesWithQuizes]);

  const passedMods = useMemo(() => {
    let res = 0;
    modulesWithQuizes.forEach(mod => {
      const filteredAttempts = getQuizAttemptsForModule(bookID, mod.number, allQuizAttempts.attempts);
      if (filteredAttempts.some(a => a.hasPassed)) res += 1;
    });
    return res;
  }, [modulesWithQuizes]);

  const inProgressMods = useMemo(() => {
    let res = 0;
    modulesWithQuizes.forEach(mod => {
      const filteredAttempts = getQuizAttemptsForModule(bookID, mod.number, allQuizAttempts.attempts);
      if (filteredAttempts.length > 0 && !filteredAttempts.some(a => a.hasPassed)) res += 1;
    });
    return res;
  }, [modulesWithQuizes]);

  return (
    <div id="quizResultsBubble">
      <h1>Your results</h1>
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <ResultsSubContainer
          flex={"1.4"}
          imgSrc={"./assets/images/Icon_Completed_Sherpa@3x.png"}
          count={passedMods}
          text={"Completed Modules"}
        />
        <ResultsSubContainer
          flex={"1"}
          imgSrc={"./assets/images/Icon_In_Progress_Sherpa@3x.png"}
          count={inProgressMods}
          text={"In Progress"}
        />
      </div>
      {/* <div>{JSON.stringify(notStartedMods)}</div> */}
      <ResultsSubContainer
        flex={"1"}
        imgSrc={"./assets/images/Icon_Not_Started_Sherpa@3x.png"}
        count={notStartedMods}
        text={"Not Started"}
      />
    </div>
  );
};

export default QuizResultsBubble;
