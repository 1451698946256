import { EmailComposer, EmailComposerOptions } from "@ionic-native/email-composer";
import { Capacitor } from "@capacitor/core";
import { AppContext } from "domain/AppContextProvider";
import { useContext } from "react";
import { SUPPORT_EMAIL_ADDRESS } from "utils/Constants";
import { IonButton, useIonToast } from "@ionic/react";
import { displaySubStatus, formatDate } from "utils/Utils";

const ContactSupport: React.FC<{ isForModal: boolean }> = ({ isForModal }) => {
  const { appContext } = useContext(AppContext);

  const [presentToast] = useIonToast();

  const contactSupport = async () => {
    try {
      const body = `<div>
          <p>Type your query here</p>
          <br />
          <h3>App Information</h3>
          <p>Platform: ${Capacitor.getPlatform()}</p>
          <p>Build: ${appContext.appInfo?.build}</p> 
          <p>Id: ${appContext.appInfo?.id}</p>
          <p>Name: ${appContext.appInfo?.name}</p>
          <p>Version: ${appContext.appInfo?.version}</p>
          <h3>Device Information</h3>
          <p>Manufacturer: ${appContext.deviceInfo?.manufacturer}</p>
          <p>Model: ${appContext.deviceInfo?.model}</p>
          <p>Name: ${appContext.deviceInfo?.name}</p>
          <p>Operating System: ${appContext.deviceInfo?.operatingSystem}</p>
          <p>OS Version: ${appContext.deviceInfo?.osVersion}</p>
          <p>Platform: ${appContext.deviceInfo?.platform}</p>
          <br />
          <h3>Subscription Information</h3>
          <p>Passenger Transport</p>
          <p>Id: ${appContext.user!.ptSub?.id}</p>
          <p>Status: ${displaySubStatus(appContext.user!.ptSub)}</p>
          <p>Road Haulage</p>
          <p>Id: ${appContext.user!.rhSub?.id}</p>
          <p>Status: ${displaySubStatus(appContext.user!.rhSub)}</p>
        </div>`;

      const email: EmailComposerOptions = {
        to: SUPPORT_EMAIL_ADDRESS,
        cc: "",
        subject: "EOS TM Training Support",
        body: body,
        isHtml: true,
      };

      EmailComposer.open(email);
    } catch (error) {
      presentToast({
        message: "Failed to open your mail app",
        mode: "ios",
        duration: 2500,
      });
    }
  };

  const getSupportEmailBodyForWeb = () => {
    return encodeURIComponent(
      `Type your query here\n\nApp Information\nPlatform: ${Capacitor.getPlatform()}\nManufacturer: ${
        appContext.deviceInfo?.manufacturer
      }\nModel: ${appContext.deviceInfo?.model}\nName: ${appContext.deviceInfo?.name}\nOperating System: ${
        appContext.deviceInfo?.operatingSystem
      }\nOS Version: ${appContext.deviceInfo?.osVersion}\nPlatform: ${
        appContext.deviceInfo?.platform
      }\n\nSubscription Information\nPassenger Transport\nId: ${appContext.user!.ptSub?.id}\nStatus: ${displaySubStatus(
        appContext.user!.ptSub
      )}\nRoad Haulage\nId: ${appContext.user!.rhSub?.id}\nStatus: ${displaySubStatus(appContext.user!.rhSub)}`
    );
  };

  return (
    <>
      <div className="n-bold fs-20 mt-32" style={{ color: isForModal ? "black" : "var(--color-black-white-subtle)" }}>
        Got a question or need help?
      </div>
      <IonButton
        href={
          Capacitor.getPlatform() === "web"
            ? `mailto:${SUPPORT_EMAIL_ADDRESS}?body=${getSupportEmailBodyForWeb()}`
            : undefined
        }
        fill="outline"
        className={`c-prim-btn br-2 mt-16 ba-none ${isForModal ? "mr-20 ml-20" : ""}`}
        style={{ "--border-color": "#006b8c" }}
        onClick={Capacitor.getPlatform() === "web" ? undefined : () => contactSupport()}
      >
        Contact support
      </IonButton>
    </>
  );
};

export default ContactSupport;
