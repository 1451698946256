import { IonButton, IonContent, IonLabel, IonModal, IonPage } from "@ionic/react";
import "./TakePhotoModal.css";

const TakePhotoModal: React.FC<{
  isOpen: boolean;
  takePhoto: () => void;
  onCancel: () => void;
}> = ({ isOpen, takePhoto, onCancel }) => {
  return (
    <IonModal isOpen={isOpen} class="takePhotoModal" backdropDismiss={false} mode={"ios"}>
      <div>
        <div id="innerContainer">
          <IonLabel>Add a photo to your certificate</IonLabel>
          <p>For verification you will need to take a photo of yourself which will appear on your certificate.</p>
        </div>
        <div id="buttonsContainer">
          <div className="modalDivider" />
          <IonButton className="maf" fill="clear" onClick={takePhoto}>
            Take a photo
          </IonButton>
          <div className="modalDivider" />
          <IonButton id="cancel" fill="clear" onClick={onCancel}>
            Cancel
          </IonButton>
        </div>
      </div>
    </IonModal>
  );
};

export default TakePhotoModal;
