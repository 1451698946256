import {
  LAST_READ_CHAPTERS_DEFAULT,
  STORAGE_KEY_BOOK_SELECTION,
  STORAGE_KEY_LAST_READ_CHAPTERS,
  STORAGE_KEY_THEME_SELECTION,
  STORAGE_KEY_USER,
  THEME_SELECTION_DEFAULT,
} from "utils/Constants";
import { BookID, Bookmark, LastReadChapters, Progress, QuizAttempt, ThemeSelectionOption, User } from "./Interfaces";
import { Storage } from "@ionic/storage";
import { Filesystem, Directory, Encoding, WriteFileResult } from "@capacitor/filesystem";

// for async storage ionic storage
export namespace LocalStorageHelper {
  export const setUser = async (user: User | null): Promise<void> => {
    const store = new Storage();
    await store.create();

    try {
      store.set(STORAGE_KEY_USER, JSON.stringify(user));
    } catch (error) {
      console.log("Failed to set user to local storage");
    }
  };

  export const getUser = async (): Promise<User | null> => {
    const store = new Storage();
    await store.create();

    try {
      const user = await store.get(STORAGE_KEY_USER);
      if (user) return JSON.parse(user);
    } catch (error) {
      console.error("Failed to get user: ", error);
    }

    return null;
  };

  export const removeUser = async () => {
    const store = new Storage();
    await store.create();

    try {
      store.remove(STORAGE_KEY_USER);
    } catch (error) {
      console.log("Failed to clear user");
    }
  };

  export const setBookSelection = async (bookID: BookID): Promise<void> => {
    const store = new Storage();
    await store.create();

    try {
      store.set(STORAGE_KEY_BOOK_SELECTION, JSON.stringify(bookID));
    } catch (error) {
      console.log("Failed to set book id to local storage");
    }
  };

  // called at app start-up or login
  export const getBookSelection = async (user: User | null): Promise<BookID> => {
    const store = new Storage();
    await store.create();

    let bookID = 1;

    try {
      const localData = await store.get(STORAGE_KEY_BOOK_SELECTION);
      if (!localData) {
        if (user?.rhSub && user.ptSub) {
          bookID = 1;
        } else if (user?.ptSub) {
          bookID = 2;
        }
      } else {
        const bid = JSON.parse(localData);
        if (bid === 1 && user?.rhSub) {
          bookID = 1;
        } else if (user?.ptSub) {
          bookID = 2;
        }
      }
    } catch (error) {
      console.error("Failed to get book selection: ", error);
    }

    return bookID;
  };

  export const removeBookSelection = async () => {
    const store = new Storage();
    await store.create();

    try {
      store.remove(STORAGE_KEY_BOOK_SELECTION);
    } catch (error) {
      console.log("Failed to clear book selection");
    }
  };

  export const setTheme = async (theme: ThemeSelectionOption): Promise<void> => {
    const store = new Storage();
    await store.create();

    try {
      store.set(STORAGE_KEY_THEME_SELECTION, JSON.stringify(theme));
    } catch (error) {
      console.log("Failed to set theme to local storage");
    }
  };

  export const getTheme = async (): Promise<ThemeSelectionOption> => {
    const store = new Storage();
    await store.create();

    try {
      const theme = await store.get(STORAGE_KEY_THEME_SELECTION);
      if (theme) return JSON.parse(theme);
    } catch (error) {
      console.error("Failed to get book selection: ", error);
    }

    return THEME_SELECTION_DEFAULT;
  };

  export const setLastReadChapters = async (lastReadChapters: LastReadChapters): Promise<void> => {
    const store = new Storage();
    await store.create();

    try {
      store.set(STORAGE_KEY_LAST_READ_CHAPTERS, JSON.stringify(lastReadChapters));
    } catch (error) {
      console.log("Failed to set last read chapters to local storage");
    }
  };

  export const getLastReadChapters = async (): Promise<LastReadChapters> => {
    const store = new Storage();
    await store.create();

    try {
      const lastReadChapters = await store.get(STORAGE_KEY_LAST_READ_CHAPTERS);
      if (lastReadChapters) return JSON.parse(lastReadChapters);
    } catch (error) {
      console.error("Failed to get last read chapters: ", error);
    }

    return LAST_READ_CHAPTERS_DEFAULT;
  };

  export const saveQuizAttempts = async (quizAttempts: QuizAttempt[], userId: string): Promise<void> => {
    try {
      Filesystem.writeFile({
        path: `QuizAttempts-${userId}.json`,
        data: JSON.stringify(quizAttempts),
        directory: Directory.Data,
        encoding: Encoding.UTF8,
        recursive: true,
      });
    } catch (error) {
      console.log("Failed to save quiz attempts to local storage");
    }
  };

  export const getQuizAttempts = async (userId: string): Promise<QuizAttempt[]> => {
    let quizAttempts: QuizAttempt[] = [];

    try {
      const file = await Filesystem.readFile({
        path: `QuizAttempts-${userId}.json`,
        directory: Directory.Data,
        encoding: Encoding.UTF8,
      });
      if (file.data) {
        quizAttempts = JSON.parse(file.data);
      }
    } catch (error) {
      console.log("Failed to get quiz attempts from local storage");
    }

    return quizAttempts;
  };

  export const saveProgress = async (progress: Progress, userId: string): Promise<void> => {
    try {
      Filesystem.writeFile({
        path: `Progress-${userId}.json`,
        data: JSON.stringify(progress),
        directory: Directory.Data,
        encoding: Encoding.UTF8,
        recursive: true,
      });
    } catch (error) {
      console.log("Failed to save user's progress to local storage");
    }
  };

  export const getProgress = async (userId: string): Promise<Progress> => {
    let prog: Progress = { rhFinishedChapters: [], ptFinishedChapters: [] };

    try {
      const file = await Filesystem.readFile({
        path: `Progress-${userId}.json`,
        directory: Directory.Data,
        encoding: Encoding.UTF8,
      });
      if (file.data) {
        prog = JSON.parse(file.data);
      }
    } catch (error) {
      console.log("Failed to get user's progress from local storage");
    }

    return prog;
  };

  // gets all bookmarks in local storage, and then saves overwrites all bookmarks for a specific user
  export const saveBookmarks = async (bookmarks: Bookmark[], userId: string): Promise<void> => {
    try {
      Filesystem.writeFile({
        path: `Bookmarks-${userId}.json`,
        data: JSON.stringify(bookmarks),
        directory: Directory.Data,
        encoding: Encoding.UTF8,
        recursive: true,
      });
    } catch (error) {
      console.log("Failed to save bookmarks to local storage", error);
    }
  };

  // gets bookmarks in local storage for a specific user
  export const getBookmarks = async (userId: string): Promise<Bookmark[]> => {
    let bookmarks: Bookmark[] = [];

    try {
      const file = await Filesystem.readFile({
        path: `Bookmarks-${userId}.json`,
        directory: Directory.Data,
        encoding: Encoding.UTF8,
      });
      if (file.data) {
        bookmarks = JSON.parse(file.data);
      }
    } catch (error) {
      console.log("Failed to get user bookmarks from local storage");
    }

    return bookmarks;
  };

  export const saveCertificate = async (data: any, fileName: string): Promise<WriteFileResult | null> => {
    try {
      return await Filesystem.writeFile({
        path: `${fileName}.pdf`,
        data: data,
        directory: Directory.Data,
        recursive: true,
      });
    } catch (error) {
      console.log("Failed to save certificate to local storage");
      return null;
    }
  };
}

// nice to have something like this as mentioned in README... :(
// const fs = require("fs");
// export const s = () => {
//   const jsonString = JSON.stringify({ id: "", name: "" });
//   fs.writeFile("./newCustomer.json", jsonString, (err: any) => {
//     if (err) {
//       console.log("Error writing file", err);
//     } else {
//       console.log("Successfully wrote file");
//     }
//   });
// };
