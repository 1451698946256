import { IonItem } from "@ionic/react";
import "./LatestBulletinBubble.css";

const LatestBulletinBubble: React.FC<{ handleClick: () => void; date: string; title: string }> = ({
  handleClick,
  date,
  title,
}) => {
  return (
    <div id="latestBulletinBubble" onClick={handleClick}>
      <h3>{date}</h3>
      <h3>{title}</h3>
    </div>
  );
};

export default LatestBulletinBubble;
