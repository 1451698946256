import { IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import CustomBackButton from "components/CustomBackButton/CustomBackButton";
import { AppContext } from "domain/AppContextProvider";
import { useAppSelector } from "hooks/customReduxHooks";
import { useContext } from "react";
import { useLocation, useParams } from "react-router";
import { formatDate, getBookClassBg } from "utils/Utils";
import "./BulletinContent.css";

const BulletinContent: React.FC<{}> = () => {
  const location = useLocation();
  const params = useParams<{ bulletinId: string }>();

  const { appContext } = useContext(AppContext);
  const bookID = appContext.bookID;

  const currentBulletin = appContext.bulletins.find(b => b.id === params.bulletinId)!;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className={getBookClassBg(bookID)}>
          <IonTitle>Bulletins</IonTitle>
          <IonButtons slot="start">
            <CustomBackButton url={location.pathname} />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="contentWrapper">
          <div id="bulletinContentContainer">
            <div>{formatDate(currentBulletin.date)}</div>
            <div>{currentBulletin.title}</div>
            <div dangerouslySetInnerHTML={{ __html: currentBulletin.html }} />
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default BulletinContent;
