import { Capacitor } from "@capacitor/core";
import { IonSpinner, useIonToast } from "@ionic/react";
import { Book, Module, QuizAttempt, User } from "domain/Interfaces";
import { useState } from "react";
import { ServerService } from "services/ServerService";
import { Camera, CameraDirection, CameraResultType } from "@capacitor/camera";
import { LocalStorageHelper } from "domain/LocalStorageHelper";
import { FileOpener } from "@awesome-cordova-plugins/file-opener";
import TakePhotoModal from "components/TakePhotoModal/TakePhotoModal";
import OverviewButton from "components/OverviewButton/OverviewButton";

const convertBlobToBase64 = async (blob: Blob): Promise<any> => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};

const GenerateCertificateButton: React.FC<{
  user: User;
  module: Module;
  book: Book;
  latestPassedAttempt: QuizAttempt;
  handleError: () => void;
}> = ({ user, module, book, latestPassedAttempt, handleError }) => {
  const [generating, setGenerating] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [presentToast] = useIonToast();

  const takePhoto = async () => {
    let permissionStatus = {
      camera: "granted",
      photos: "granted",
    };
    if (Capacitor.getPlatform() !== "web") {
      permissionStatus = await Camera.requestPermissions({ permissions: ["camera", "photos"] });
    }
    if (permissionStatus.camera === "denied" && permissionStatus.photos === "denied") {
      presentToast({
        message: "Photo permission must be granted for certificate generation.",
        mode: "ios",
        duration: 2500,
      });
      setModalVisible(false);
    } else {
      try {
        const image = await Camera.getPhoto({
          quality: 90,
          allowEditing: false,
          resultType: CameraResultType.Base64,
          direction: CameraDirection.Front,
          saveToGallery: true,
        });

        setModalVisible(false);
        generateCertificate(image.base64String!);
      } catch (error: any) {
        // user pressed cancel or the device just failed to obtain a photo (possibly because the user had camera/photo permissions turned off)
        if (
          error.errorMessage === "User denied access to camera" ||
          error.errorMessage === "User denied access to photos"
        ) {
          presentToast({
            message: "Please check your permissions.",
            mode: "ios",
            duration: 2500,
          });
        }
      }
    }
  };

  const generateCertificate = async (verificationImgBase64String: string) => {
    setGenerating(true);

    const blob = await ServerService.generateCertificate(
      user,
      module,
      book,
      latestPassedAttempt,
      verificationImgBase64String
    );
    if (!blob) {
      handleError();
    } else {
      if (Capacitor.getPlatform() === "web") {
        var fileBlob = new Blob([blob], { type: "application/pdf" });

        const data = window.URL.createObjectURL(fileBlob);
        var link = document.createElement("a");
        link.href = data;
        link.download = `certificate-book-${book.bookID}-module-${module.number}.pdf`;
        link.click();
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
        }, 100);
      } else {
        const base64 = await convertBlobToBase64(blob);

        if (base64 != null) {
          let imageData = base64.split(",");
          if (imageData.length > 1) {
            imageData = imageData[1];
            const res = await LocalStorageHelper.saveCertificate(
              imageData,
              `certificate-book-${book.bookID}-module-${module.number}`
            );
            if (!res) {
              handleError();
            } else {
              FileOpener.open(res.uri, "application/pdf");
            }
          } else {
            // error?
            handleError();
          }
        } else {
          // error?
          handleError();
        }
      }
    }

    setGenerating(false);
  };

  return (
    <>
      <OverviewButton
        disabled={generating}
        text={generating ? "" : "Generate certificate"}
        clickHandler={() => setModalVisible(true)}
      >
        <IonSpinner color={"white"} />
      </OverviewButton>
      <TakePhotoModal isOpen={modalVisible} onCancel={() => setModalVisible(false)} takePhoto={takePhoto} />
    </>
  );
};

export default GenerateCertificateButton;
