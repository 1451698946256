import { IonIcon, IonPage } from "@ionic/react";
import { QuizQuestionAnswer, LiveQuizQuestion } from "domain/Interfaces";
import { useState } from "react";
import "./AnswerBox.css";

const AnswerBox: React.FC<{
  fontSize: string;
  question: LiveQuizQuestion;
  answer: QuizQuestionAnswer;
  handleSelection: () => void;
  isQuestionConfirmed: boolean;
  selected: boolean;
}> = ({ fontSize, question, answer, handleSelection, isQuestionConfirmed, selected }) => {
  // const [selected, setSelected] = useState(false);

  const getIcon = () => {
    if (isQuestionConfirmed) {
      if (answer.isCorrect) {
        return <IonIcon id="answerIcon" icon={"./assets/svgs/Button_Answer_Tick.svg"} />;
      } else if (selected) {
        return <IonIcon id="answerIcon" icon={"./assets/svgs/Button_Answer_Cross.svg"} />;
      }
    } else if (question.type === "Multi Select") {
      if (selected) {
        return <IonIcon id="multiAnswerIcon" icon={"./assets/svgs/Button_Tickbox_Active.svg"} />;
      } else {
        return <IonIcon id="multiAnswerIcon" icon={"./assets/svgs/Button_Tickbox_Default.svg"} />;
      }
    }
  };

  const getBoxStyles = () => {
    let style = "";
    style += isQuestionConfirmed ? "answered" : "unanswered";
    style += answer.isCorrect ? " correct" : " incorrect";
    style += selected ? " selected" : " unselected";

    return style;
  };

  const boxStyle = getBoxStyles();

  return (
    <>
      {/* <i style={{ color: "#56A8F2", fontSize: "14px" }}>
        {"Is correct? "}
        <b>
          <span style={{ textTransform: "uppercase" }}>{JSON.stringify(answer.isCorrect)}</span>
        </b>
      </i> */}
      <div
        className={boxStyle}
        id="answerBoxContainer"
        onClick={() => {
          if (!isQuestionConfirmed) handleSelection();
        }}
      >
        <div style={{ fontSize: fontSize }} id="answerText">
          {answer.text}
        </div>
        {getIcon()}
      </div>
    </>
  );
};

export default AnswerBox;
