import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import CustomBackButton from "components/CustomBackButton/CustomBackButton";
import PreviousResultItem from "components/PreviousResultItem/PreviousResultItem";
import { AppContext } from "domain/AppContextProvider";
import { useAppSelector } from "hooks/customReduxHooks";
import { useContext } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { getBookClassBg, getCurrentBookFromBookID, getQuizAttemptsForModule } from "utils/Utils";
import "./QuizPreviousResultsList.css";

const QuizPreviousResults: React.FC<{}> = () => {
  const location = useLocation();
  const history = useHistory();

  const { appContext } = useContext(AppContext);
  const { allQuizAttempts } = useAppSelector(state => state);

  const params = useParams<{ moduleNumber: string }>();

  const books = appContext.books;
  const bookID = appContext.bookID;
  const currentBook = getCurrentBookFromBookID(books, bookID);
  const currentModule = currentBook.modules.find(mod => mod.number === parseInt(params.moduleNumber))!;
  const filteredQuizAttempts = getQuizAttemptsForModule(bookID, currentModule.number, allQuizAttempts.attempts);

  // const attemptsCopy = [...filteredQuizAttempts];

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className={getBookClassBg(bookID)}>
          <IonTitle>Previous Results</IonTitle>
          <IonButtons slot="start">
            <CustomBackButton url={location.pathname} />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent scrollY={false}>
        <div className="contentWrapper">
          <div id="quizResultsContainer">
            <h2>Module {params.moduleNumber}</h2>
            <h1>{currentModule?.name}</h1>
            <h3>Previous Results</h3>
            <div className="basicDivider" />
            {filteredQuizAttempts.map((attempt, index) => (
              <PreviousResultItem
                key={index}
                handleClick={() => history.push(`${location.pathname}/result-${index}`)}
                quizAttempt={attempt}
                module={currentModule!}
                isLastItem={index === filteredQuizAttempts.length - 1}
              />
            ))}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default QuizPreviousResults;
