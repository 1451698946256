import { IonIcon } from "@ionic/react";
import { AppContext } from "domain/AppContextProvider";
import { Module } from "domain/Interfaces";
import { useAppSelector } from "hooks/customReduxHooks";
import { useContext } from "react";
import { useHistory, useLocation } from "react-router";
import { getQuizAttemptsForModule, getStatusTextForQuizAttemptsInModule } from "utils/Utils";
import "./QuizBubble.css";

const QuizBubble: React.FC<{ module: Module; handleClick: () => void }> = ({ module, handleClick }) => {
  const history = useHistory();
  const location = useLocation();

  const { allQuizAttempts } = useAppSelector(state => state);
  const { appContext } = useContext(AppContext);
  const bookID = appContext.bookID;

  const filteredQuizAttempts = getQuizAttemptsForModule(bookID, module.number, allQuizAttempts.attempts);
  const hasAttemptedQuiz = filteredQuizAttempts.length > 0;
  const hasPassedAttempt = filteredQuizAttempts.some(attempt => attempt.hasPassed);

  const getBubbleColourClass = () => {
    if (!hasAttemptedQuiz) {
      return "notStarted";
    } else if (hasPassedAttempt) {
      return "passed";
    } else {
      return "inProgress";
    }
  };

  return (
    <div id="quizBubble" className={getBubbleColourClass()} onClick={handleClick}>
      <h3 id="quizBubbleModuleNum" className={getBubbleColourClass()}>
        Module {module.number}
      </h3>
      <h1 id="quizBubbleModuleName" className={getBubbleColourClass()}>
        {module.name}
      </h1>
      <div id="statusRowContainer">
        <div>
          {hasAttemptedQuiz && (
            <img style={{ marginRight: "5px" }} id="quizBubbleStatusIcon" className={getBubbleColourClass()} />
          )}
          <span id="quizBubbleStatus" className={getBubbleColourClass()}>
            {getStatusTextForQuizAttemptsInModule(filteredQuizAttempts)}
          </span>
        </div>
        <IonIcon
          className={getBubbleColourClass()}
          id="quizBubbleArrowIcon"
          icon={"./assets/svgs/Button_Disclosure_Right_Arrow.svg"}
        />
      </div>
      <img id="quizBubbleBackgroundIcon" className={getBubbleColourClass()} />
    </div>
  );
};

export default QuizBubble;
