import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonPage,
  IonToolbar,
} from "@ionic/react";
import LoginTermsAndConditions from "components/LoginTermsAndConditions/LoginTermsAndConditions";
import { useState } from "react";
import "./ResetPassword.css";

const ResetPassword: React.FC<{}> = () => {
  const [email, setEmail] = useState("");

  const handleReset = () => {};

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="resetPassToolbar">
          <IonButtons>
            <IonBackButton defaultHref="/login" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent id="resetPasswordContent" scrollY={false}>
        <div className="contentWrapper" style={{ display: "flex", flexDirection: "column" }}>
          <div id="resetContentContainer">
            <div>
              <div className="loginTitle">Forgot password?</div>
              <div id="resetPassInfo">
                Forgot your password? Please enter your username or email address. You will receive a link to create a
                new password via email.
              </div>
              <div className="inputLabel">Username or email address</div>
              <IonInput className="userInput" type="email" onIonChange={e => setEmail(e.detail.value!)} />
              <div style={{ display: "flex", justifyContent: "center" }}>
                <IonButton
                  className="loginBtn"
                  disabled={email === ""}
                  style={{ "--opacity": email === "" ? "0.4" : "1" }}
                  onClick={handleReset}
                >
                  Reset password
                </IonButton>
              </div>
            </div>
            <LoginTermsAndConditions />
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ResetPassword;
