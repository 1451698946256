import { IonButton, IonIcon } from "@ionic/react";
import SwiperCore from "swiper";
import "./SwiperControls.css";

const SwiperControls: React.FC<{
  swiperRef: SwiperCore | null;
  showPrev: boolean;
  showNext: boolean;
  middleButton?: { text: string; handler?: () => void; disabled: boolean };
  finishButton?: { handler: () => void };
}> = ({ swiperRef, showPrev, showNext, middleButton, finishButton }) => {
  return (
    <div id="swiper-controls-container">
      <div id="swiper-controls">
        <IonButton
          onClick={() => swiperRef?.slidePrev()}
          class="swiperControlPrev"
          fill="clear"
          style={{ visibility: showPrev ? "visible" : "hidden" }}
        >
          <IonIcon slot="start" icon={"./assets/svgs/Button_Disclosure_Left.svg"} />
          Previous
        </IonButton>
        {middleButton && (
          <IonButton disabled={middleButton.disabled} class="middleBtn" fill="clear" onClick={middleButton.handler}>
            {middleButton.text}
          </IonButton>
        )}
        {finishButton ? (
          <IonButton className="finishButton" fill="clear" onClick={finishButton.handler}>
            Finish
          </IonButton>
        ) : (
          <IonButton
            onClick={() => swiperRef?.slideNext()}
            class="swiperControlNext"
            fill="clear"
            style={{ visibility: showNext ? "visible" : "hidden" }}
          >
            Next
            <IonIcon slot="end" icon={"./assets/svgs/Button_Disclosure_Right.svg"} />
          </IonButton>
        )}
      </div>
    </div>
  );
};

export default SwiperControls;
