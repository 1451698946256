import React, { useEffect } from "react";
import { IonApp } from "@ionic/react";
import { AT_API_KEY, EOS_SERVER_BASE_URL } from "./utils/Constants";
import { PushNotifications, Token } from "@capacitor/push-notifications";
import { FCM } from "@capacitor-community/fcm";
import { Capacitor } from "@capacitor/core";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

import "react-image-lightbox/style.css";

/* Theme variables */
import "./theme/variables.css";
import "./theme/fonts.css";
import "./theme/globalStyles.css";
import "./theme/utilityClasses.css";

import NavRouter from "navigation/NavRouter";
import Airtable from "airtable";

import { Provider } from "react-redux";
import { store } from "domain/Store";
import AppContextProvider from "domain/AppContextProvider";

import { Subscriptions } from "capacitor-subscriptions";

Airtable.configure({
  endpointUrl: "https://api.airtable.com",
  apiKey: AT_API_KEY,
});

const App: React.FC = props => {
  useEffect(() => {
    Subscriptions.setGoogleVerificationDetails({
      // googleVerifyEndpoint: "https://aimermedia-api-live.co.uk/aimersubs/verifyWithGoogleAimer",
      googleVerifyEndpoint: `${EOS_SERVER_BASE_URL}/verifyWithGoogleAimer`,
      bid: "uk.co.classprofessional.eos",
    });

    configurePushNotifications();
  }, []);

  const configurePushNotifications = () => {
    if (Capacitor.getPlatform() === "web") return;

    PushNotifications.checkPermissions().then(res => {
      if (res.receive !== "granted") {
        PushNotifications.requestPermissions().then(res => {
          if (res.receive === "denied") {
            console.log("Push Notification permission denied");
          } else {
            console.log("Push Notification permission granted");
            registerPushNotifcations();
          }
        });
      } else {
        registerPushNotifcations();
      }
    });
  };

  const registerPushNotifcations = async () => {
    // Register with Apple / Google to receive push via APNS/FCM
    await PushNotifications.register();

    subscribeToPushNotificationTopics();

    // On success, we should be able to receive notifications
    PushNotifications.addListener("registration", (token: Token) => {
      console.log("Push notification registration success. Token: " + token.value);
    });

    // Some issue with our setup and push will not work
    PushNotifications.addListener("registrationError", (error: any) => {
      console.log("Error on push notifcation registration: " + JSON.stringify(error));
    });
  };

  const subscribeToPushNotificationTopics = async () => {
    // FCM.unsubscribeFrom({ topic: "testUsers" });
    // FCM.subscribeTo({ topic: "testUsers" })
    //   .then(r => console.log(`subscribed to testUsers`))
    //   .catch(err => console.log("error subbing to topic testUsers: ", err));

    // FCM.unsubscribeFrom({ topic: "allUsers" });
    FCM.subscribeTo({ topic: "allUsers" })
      .then(r => console.log(`subscribed to allUsers`))
      .catch(err => console.log("error subbing to topic allUsers: ", err));
  };

  return (
    <Provider store={store}>
      <AppContextProvider>
        <IonApp>
          <NavRouter />
        </IonApp>
      </AppContextProvider>
    </Provider>
  );
};

export default App;
