import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Bookmark, User } from "domain/Interfaces";
import { LocalStorageHelper } from "domain/LocalStorageHelper";
import { ServerService } from "services/ServerService";

interface BookmarksState {
  value: Bookmark[];
}

const initialState: BookmarksState = {
  value: [],
};

export const bookmarksSlice = createSlice({
  name: "bookmarks",
  initialState: initialState,
  reducers: {
    setBookmarks: (state, action: PayloadAction<{ bms: Bookmark[]; userId: string }>) => {
      state.value = action.payload.bms;
      // LocalStorageHelper.saveBookmarks(action.payload.bms, action.payload.userId);
    },
    addBookmark: (state, action: PayloadAction<{ bm: Bookmark; user: User }>) => {
      state.value.push(action.payload.bm);
      ServerService.addBookmark(action.payload.user, action.payload.bm);
      LocalStorageHelper.saveBookmarks(state.value, action.payload.user.code);
    },
    removeBookmark: (state, action: PayloadAction<{ bm: Bookmark; user: User }>) => {
      const index = state.value.findIndex(
        b =>
          b.bookId === action.payload.bm.bookId &&
          b.chapterNumber === action.payload.bm.chapterNumber &&
          b.moduleNumber === action.payload.bm.moduleNumber
      );
      state.value.splice(index, 1);
      ServerService.delBookmark(action.payload.user, action.payload.bm);
      LocalStorageHelper.saveBookmarks(state.value, action.payload.user.code);
    },
  },
});

export const { setBookmarks, addBookmark, removeBookmark } = bookmarksSlice.actions;

export default bookmarksSlice.reducer;
