import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FONT_SIZE_PERCENTAGE_DEFAULT, FONT_SIZE_PERCENTAGE_SCALE_AMOUNT } from "utils/Constants";

interface FontSizePercentageState {
  percentage: number;
}

const initialState: FontSizePercentageState = {
  percentage: FONT_SIZE_PERCENTAGE_DEFAULT,
};

export const fontSizePercentageSlice = createSlice({
  name: "fontSizePercentage",
  initialState: initialState,
  reducers: {
    setFontSizePercentage: (state, action: PayloadAction<number>) => {
      state.percentage = action.payload;
    },
    increaseFontSizePercentage: state => {
      if (state.percentage < 200) {
        state.percentage += FONT_SIZE_PERCENTAGE_SCALE_AMOUNT;
      }
    },
    decreaseFontSizePercentage: state => {
      if (state.percentage > 50) {
        state.percentage -= FONT_SIZE_PERCENTAGE_SCALE_AMOUNT;
      }
    },
  },
});

export const { setFontSizePercentage, increaseFontSizePercentage, decreaseFontSizePercentage } = fontSizePercentageSlice.actions;

export default fontSizePercentageSlice.reducer;
