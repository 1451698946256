import { IonIcon, IonItem, IonLabel } from "@ionic/react";
import React from "react";
import "./MenuItem.css";

const MenuItem: React.FC<{
  text: string;
  subText?: string;
  handleClick?: () => void;
  imgSrc: string;
  link?: string;
}> = ({ text, subText, handleClick, imgSrc, link }) => {
  return (
    <IonItem
      detail={false}
      href={link}
      target="_blank"
      onClick={handleClick ? handleClick : () => {}}
      id="menuItemContainer"
    >
      <img width={"22px"} height={"22px"} style={{ marginRight: "20px" }} src={imgSrc} />
      <div>
        <IonLabel style={{ marginBottom: subText ? "0" : "14px" }} id="menuItemText">
          {text}
        </IonLabel>
        {subText && (
          <IonLabel style={{ marginBottom: "14px" }} id="menuItemSubText">
            {subText}
          </IonLabel>
        )}
      </div>
    </IonItem>
  );
};

export default MenuItem;
