import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonModal,
  IonTitle,
  IonToolbar,
  useIonLoading,
} from "@ionic/react";
import { AppContext } from "domain/AppContextProvider";
import { LocalStorageHelper } from "domain/LocalStorageHelper";
import { useContext, useRef } from "react";
import SubscriptionsList from "components/SubscriptionsList/SubscriptionsList";
import RequestDeleteButton from "components/RequestDeleteButton/RequestDeleteButton";
import "./InAppPurchasesModal.css";

const InAppPurchasesModal: React.FC<{
  isOpen: boolean;
  handleClose: () => void;
}> = ({ isOpen, handleClose }) => {
  const { appContext } = useContext(AppContext);

  const modalRef = useRef<HTMLIonModalElement>(null);

  const [presentOverlay] = useIonLoading();

  const handleLogOut = async () => {
    presentOverlay({ message: "Logging out..." });
    await LocalStorageHelper.removeUser();
    await LocalStorageHelper.removeBookSelection();
    setTimeout(() => {
      window.location.reload();
    }, 1500);
  };

  return (
    <IonModal
      ref={modalRef}
      canDismiss={true}
      handle={false}
      className="contentsModal"
      isOpen={isOpen}
      onWillDismiss={handleClose}
    >
      <IonHeader>
        <IonToolbar style={{ "--background": "#006b8c" }}>
          <IonButtons slot="start">
            <IonButton style={{ visibility: "hidden" }} />
          </IonButtons>
          <IonTitle class="ion-text-center">Account</IonTitle>
          <IonButtons slot="end">
            <IonButton style={{ visibility: "hidden" }} />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent style={{ "--background": "white" }}>
        <div className="modalContent pl-20 pr-20 pt-20" style={{ background: "white", height: "100%" }}>
          <div id="iapContentContainer" className="flex flex-d-col">
            <div className="o-med fs-22 c-prim-50">My Account</div>
            <div className="n-reg fs-13 mt-16" style={{ color: "#666666" }}>
              Signed in as
            </div>
            <div className="n-reg mt-4">{appContext.user?.email}</div>
            <IonButton
              className="mt-32 c-red br-2 ba-none mr-20 ml-20"
              fill="outline"
              style={{ "--border-color": "red" }}
              onClick={handleLogOut}
            >
              Log out
            </IonButton>
            <RequestDeleteButton addMargin />
            <SubscriptionsList modalRef={modalRef} />
          </div>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default InAppPurchasesModal;
