import { IonButton, IonButtons, IonContent, IonHeader, IonList, IonModal, IonTitle, IonToolbar } from "@ionic/react";
import ChapterListItem from "components/ChapterListItem/ChapterListItem";
import { AppContext } from "domain/AppContextProvider";
import { Module } from "domain/Interfaces";
import { useContext } from "react";
import { getBookClassBg } from "utils/Utils";
import "./ContentsModal.css";

const ContentsModal: React.FC<{
  module: Module;
  isOpen: boolean;
  handleClose: () => void;
  moveToSlide: (index: number) => void;
}> = ({ module, isOpen, handleClose, moveToSlide }) => {
  const { appContext } = useContext(AppContext);
  const bookID = appContext.bookID;

  return (
    <IonModal handle={false} className="contentsModal" isOpen={isOpen} onWillDismiss={handleClose}>
      <IonHeader>
        <IonToolbar className={getBookClassBg(bookID)}>
          <IonButtons slot="start">
            {/* render button so that title is centered */}
            <IonButton style={{ visibility: "hidden" }}>Close</IonButton>
          </IonButtons>
          <IonTitle class="ion-text-center">Module {module.number}</IonTitle>
          <IonButtons slot="end">
            <IonButton id="modalClose" onClick={handleClose}>
              Close
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="modalContent">
          <IonList>
            {module?.chapters.map((chapter, index) => {
              return (
                <ChapterListItem
                  key={index}
                  chapter={chapter}
                  handleClick={() => {
                    handleClose();
                    moveToSlide(index);
                  }}
                  moduleNumber={module.number}
                />
              );
            })}
          </IonList>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default ContentsModal;
