import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { QUIZ_PASS_PERCENTAGE } from "utils/Constants";
import { QuizAttempt, User } from "domain/Interfaces";
import { LocalStorageHelper } from "domain/LocalStorageHelper";

interface QuizAttemptsState {
  attempts: QuizAttempt[];
}

const initialState: QuizAttemptsState = {
  attempts: [],
};

// sorts attempts with latest date first
const sortQuizAttemptsByDate = (attempts: QuizAttempt[]) => {
  return attempts.sort((a, b) =>
    a.dateTimeStarted > b.dateTimeStarted ? -1 : a.dateTimeStarted < b.dateTimeStarted ? 1 : 0
  );
};

// ********************* IMPORTANT!! *********************
// IF YOU NEED TO MODIFY QUIZ ATTEMPTS STATE, DO THIS:
// 1) MAKE A COPY OF THE EXISTING STATE
// 2) MODIFY THE COPY
// 3) SORT THE COPY <-- the important part (see sortQuizAttemptsByDate)
// 4) REPLACE THE STATE
//
// DON'T JUST MODIFY THE STATE. THIS IS TO ENSURE THAT I CAN ALWAYS JUST UPDATE THE FIRST INDEX (0) OF A BOOK'S ATTEMPTS
export const quizAttemptsSlice = createSlice({
  name: "quizAttempts",
  initialState: initialState,
  reducers: {
    setQuizAttempts: (state, action: PayloadAction<{ attempts: QuizAttempt[]; userId: string }>) => {
      state.attempts = sortQuizAttemptsByDate(action.payload.attempts);

      LocalStorageHelper.saveQuizAttempts(state.attempts, action.payload.userId);
    },
    addQuizAttempt: (state, action: PayloadAction<{ attempt: QuizAttempt; userId: string }>) => {
      const copy = [...state.attempts, action.payload.attempt];
      state.attempts = sortQuizAttemptsByDate(copy);

      LocalStorageHelper.saveQuizAttempts(state.attempts, action.payload.userId);
    },
    // called after an answer is CONFIRMED for a question
    updateQuizAttempt: (
      state,
      action: PayloadAction<{
        user: User;
        bookID: number;
        addPoint: boolean;
        isFinished: boolean;
        answeredQuestion: {
          questionNumber: number;
          questionText: string;
          questionType: string;
          caseStudyAnswer: { text: string; score: number };
          multiChoiceAnswers: { text: string; isCorrect: boolean }[];
        };
      }>
    ) => {
      const copyA = sortQuizAttemptsByDate(state.attempts.filter(a => a.bookID === action.payload.bookID));
      const copyB = sortQuizAttemptsByDate(state.attempts.filter(a => a.bookID !== action.payload.bookID));

      copyA[0].questionsAnswered.push(action.payload.answeredQuestion);
      copyA[0].score += action.payload.addPoint ? 1 : 0;
      if (action.payload.isFinished) {
        const scoreToAchieve = Math.ceil(QUIZ_PASS_PERCENTAGE * copyA[0].maxScore);
        copyA[0].hasPassed = copyA[0].score >= scoreToAchieve;
        copyA[0].isFinished = true;
      }

      state.attempts = sortQuizAttemptsByDate(copyA.concat(copyB));
    },
  },
});

export const { setQuizAttempts, addQuizAttempt, updateQuizAttempt } = quizAttemptsSlice.actions;

export default quizAttemptsSlice.reducer;
