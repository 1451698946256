import { IonList } from "@ionic/react";
import ChapterListItem from "components/ChapterListItem/ChapterListItem";
import { AppContext } from "domain/AppContextProvider";
import { useAppSelector } from "hooks/customReduxHooks";
import { useContext } from "react";
import { useHistory } from "react-router";
import { getCurrentBookFromBookID } from "utils/Utils";
import "./BookmarksList.css";

const BookmarksList: React.FC<{ showAll: boolean; closeModalHandler?: () => void }> = ({
  showAll,
  closeModalHandler,
}) => {
  const history = useHistory();

  const { appContext } = useContext(AppContext);
  const { bookmarks } = useAppSelector(state => state);

  const books = appContext.books;
  const bookID = appContext.bookID;
  const currentBook = getCurrentBookFromBookID(books, bookID);

  let bookmarksToShow = [...bookmarks.value.filter(b => b.bookId === bookID)];

  if (!showAll) bookmarksToShow = bookmarksToShow.slice(0, 5);

  return (
    <div id="bookmarksListContainer">
      {bookmarksToShow.length > 0 ? (
        <IonList>
          {bookmarksToShow.map((item, index) => {
            return (
              <div key={index}>
                <div
                  style={{
                    paddingTop: index !== 0 ? "16px" : "0px",
                    fontSize: "13px",
                    fontFamily: "orbi-bold",
                    color: "var(--dash-bookmarks-mod-title)",
                    backgroundColor: "var(--content-bg)",
                  }}
                >
                  Module {currentBook.modules.find(m => m.number === item.moduleNumber)?.number} -{" "}
                  {currentBook.modules.find(m => m.number === item.moduleNumber)?.name}
                </div>
                <ChapterListItem
                  chapter={
                    currentBook.modules
                      .find(m => m.number === item.moduleNumber)
                      ?.chapters.find(c => c.number === item.chapterNumber)!
                  }
                  handleClick={() => {
                    if (closeModalHandler) closeModalHandler();
                    history.push(`/modules/module-${item.moduleNumber}/chapter-content`, {
                      chapterNumber: item.chapterNumber,
                    });
                  }}
                  moduleNumber={item.moduleNumber}
                />
              </div>
            );
          })}
        </IonList>
      ) : (
        <div className="emptyBookmarksContainer">
          You haven’t bookmarked any Module chapters. Get bookmarking to quickly access the chapters you need.
        </div>
      )}
    </div>
  );
};

export default BookmarksList;
