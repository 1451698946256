import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonLabel,
  IonPage,
  IonRange,
  IonTextarea,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useAppDispatch, useAppSelector } from "hooks/customReduxHooks";
import { Swiper, SwiperSlide } from "swiper/react";
import { getBookClassBg, getCurrentBookFromBookID, getPrevRoute, getQuizAttemptsForModule } from "utils/Utils";
import { useHistory, useLocation, useParams } from "react-router";
import { AppContext } from "domain/AppContextProvider";
import { setQuizAttempts, updateQuizAttempt } from "features/quizAttempts/quizAttemptsSlice";
import {
  decreaseFontSizePercentage,
  increaseFontSizePercentage,
} from "features/fontSizePercentage/fontSizePercentageSlice";
import { ServerService } from "services/ServerService";
import { store } from "domain/Store";
import { LocalStorageHelper } from "domain/LocalStorageHelper";
import { LiveQuizQuestion, QuestionResponse, QuizQuestionAnswer } from "domain/Interfaces";
import { CASE_STUDY_PASS_PERCENTAGE } from "utils/Constants";
import TextControlsPopover from "components/TextControlsPopover/TextControlsPopover";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import SwiperControls from "components/SwiperControls/SwiperControls";
import SwiperCore from "swiper";
import AnswerBox from "components/AnswerBox/AnswerBox";
import CustomBackButton from "components/CustomBackButton/CustomBackButton";
import "./Quiz.css";

// Quiz functionality:
// User must enter an answer before going on to the next question
// User can stop a quiz mid way through and resume it later unless the set of live questions has changed
// user can select all options for muli-select (e.g. the user can select all 4 options even if the question only require 2 answers for a correct answer).
// for multi-select, a user must ONLY select the correct answers for the whole question to be correct
// ... (e.g. if there are 3 correct answers, and 4 options in total, the user must select the 3 correct answers for the question to be correct.
// ... the answer is not correct if all 4 options are selected)
const Quiz: React.FC<{}> = ({}) => {
  const popoverRef = useRef<HTMLIonPopoverElement>(null);
  const location = useLocation();
  const history = useHistory();
  const params = useParams<{ moduleNumber: string }>();

  const dispatch = useAppDispatch();
  const { allQuizAttempts, fontSizePercentage } = useAppSelector(state => state);
  const { appContext } = useContext(AppContext);

  const books = appContext.books;
  const bookID = appContext.bookID;
  const currentBook = getCurrentBookFromBookID(books, bookID);
  const currentModule = currentBook.modules.find(mod => mod.number === parseInt(params.moduleNumber))!;
  const filteredQuizAttempts = getQuizAttemptsForModule(bookID, currentModule.number, allQuizAttempts.attempts);
  const currentQuizAttempt = filteredQuizAttempts[0];
  const initialSlideIndex = currentQuizAttempt.questionsAnswered.length;
  const questionSet = useMemo(() => {
    // init random question set only once for better performance
    return randomiseQuizQuestions();
  }, []);
  const initialQuestionReponses = useMemo(() => initializeQuestionReponses(), []);

  const anchorRef = useRef<HTMLDivElement[]>(new Array(questionSet.length));

  const [textControlsPopoverOpen, setTextControlsPopoverOpen] = useState(false);
  const [swiperRef, setSwiperRef] = useState<SwiperCore | null>(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(initialSlideIndex);
  const [questionResponses, setQuestionResponses] = useState<QuestionResponse[]>(initialQuestionReponses);

  // console.log("questionSet: ", questionSet);

  const currentQuestion = questionSet[currentQuestionIndex];

  function initializeQuestionReponses() {
    return questionSet.map((question, index) => {
      const indexOfQuestion = currentQuizAttempt.questionsAnswered.findIndex(q => q.questionNumber === index + 1);
      const isConfirmed = indexOfQuestion !== -1;

      let multiChoiceAnswers: { text: string; isCorrect: boolean }[] = isConfirmed
        ? currentQuizAttempt.questionsAnswered[indexOfQuestion].multiChoiceAnswers
        : [];
      let caseStudyAnswer: { text: string; score: number } = isConfirmed
        ? currentQuizAttempt.questionsAnswered[indexOfQuestion].caseStudyAnswer
        : { text: "", score: -1 };

      return {
        isConfirmed: isConfirmed,
        caseStudyAnswer: caseStudyAnswer,
        multiChoiceAnswers: multiChoiceAnswers,
      };
    });
  }

  function randomiseQuizQuestions() {
    const questionSet: LiveQuizQuestion[] = [];

    // get answered questions (case study and non-case study)
    const questionsAnswered: LiveQuizQuestion[] = [];
    const qTextArr = currentQuizAttempt.questionsAnswered.map(q => q.questionText);
    qTextArr.forEach(text => {
      const q = currentModule.quizQuestions.find(q => q.text === text);
      if (q) questionsAnswered.push(q);
    });

    // get answered non-case study questions and add them to result set
    const nonCaseStudyQuestionsAnswered = questionsAnswered.filter(q => q.type != "Case Study");
    questionSet.push(...nonCaseStudyQuestionsAnswered);

    const totalNonCaseStudyQuestions = currentModule.quizQuestions.filter(q => q.type != "Case Study");

    // adjust loop limit for modules that have less than 10 non-case study quiz questions.
    // this way, there will only be a maximum of 10 non-case study questions (plus case-study questions) for each quiz
    let loopLimit = 10;
    if (totalNonCaseStudyQuestions.length < 10) loopLimit = totalNonCaseStudyQuestions.length;

    while (questionSet.length < loopLimit) {
      const randomIndex = Math.floor(Math.random() * totalNonCaseStudyQuestions.length);
      const randomQuestion = totalNonCaseStudyQuestions[randomIndex];
      const randomQuestionAlreadyAdded = questionSet.find(q => q.id === randomQuestion.id);

      if (!randomQuestionAlreadyAdded) {
        questionSet.push(randomQuestion);
      }
    }

    const totalCaseStudyQuestions = currentModule.quizQuestions.filter(q => q.type == "Case Study");
    return [...questionSet, ...totalCaseStudyQuestions];
  }

  function handleMultiChoiceSelection(answerText: string) {
    const copy = [...questionResponses];

    const answer = {
      text: answerText,
      isCorrect: currentQuestion.answers.filter(a => a.isCorrect).findIndex(a => a.text === answerText) !== -1,
    };

    if (currentQuestion.type === "Single Select") {
      copy[currentQuestionIndex].multiChoiceAnswers.push(answer);
      copy[currentQuestionIndex].isConfirmed = true;

      // update store
      updateQuizAttemptInStore(copy[currentQuestionIndex]);
    } else {
      // multi select so toggle (remove/add) answer instead of just adding it
      const indexOfAnswer = copy[currentQuestionIndex].multiChoiceAnswers.findIndex(a => a.text === answerText);
      if (indexOfAnswer === -1) {
        // add
        copy[currentQuestionIndex].multiChoiceAnswers.push(answer);
      } else {
        // remove
        copy[currentQuestionIndex].multiChoiceAnswers.splice(indexOfAnswer, 1);
      }
    }

    setQuestionResponses(copy);
  }

  async function updateQuizAttemptInStore(questionResponse: QuestionResponse) {
    const correctAnswers = currentQuestion.answers.filter(a => a.isCorrect).map(a => a.text);
    const isLastQuestion = currentQuestionIndex === questionSet.length - 1;

    // update store
    if (currentQuestion.type === "Case Study") {
      const scoreToAchieve = Math.ceil(CASE_STUDY_PASS_PERCENTAGE * currentQuestion.maxScore);

      //update with case study answer
      dispatch(
        updateQuizAttempt({
          user: appContext.user!,
          bookID: bookID,
          addPoint: questionResponse.caseStudyAnswer.score >= scoreToAchieve,
          isFinished: isLastQuestion,
          answeredQuestion: {
            questionNumber: currentQuestionIndex + 1,
            questionText: currentQuestion.text,
            questionType: currentQuestion.type,
            caseStudyAnswer: {
              text: questionResponse.caseStudyAnswer.text,
              score: questionResponse.caseStudyAnswer.score,
            },
            multiChoiceAnswers: [],
          },
        })
      );
    } else {
      // update with multi choice answer
      dispatch(
        updateQuizAttempt({
          user: appContext.user!,
          bookID: bookID,
          addPoint:
            questionResponse.multiChoiceAnswers.length > 0 &&
            correctAnswers.every(a => questionResponse.multiChoiceAnswers.map(a => a.text).includes(a)) &&
            !questionResponse.multiChoiceAnswers.some(a => !a.isCorrect),
          isFinished: isLastQuestion,
          answeredQuestion: {
            questionNumber: currentQuestionIndex + 1,
            questionText: currentQuestion.text,
            questionType: currentQuestion.type,
            caseStudyAnswer: { text: "", score: -1 },
            multiChoiceAnswers: questionResponse.multiChoiceAnswers,
          },
        })
      );
    }

    if (isLastQuestion) {
      const response = await ServerService.syncLocalAttemptsWithRemoteDB(
        store.getState().allQuizAttempts.attempts,
        appContext.user!
      );
      dispatch(setQuizAttempts({ attempts: response, userId: appContext.user?.code! }));
    } else {
      LocalStorageHelper.saveQuizAttempts(store.getState().allQuizAttempts.attempts, appContext.user?.code!);
    }
  }

  function checkIfQuestionIsConfirmed() {
    return questionResponses[currentQuestionIndex].isConfirmed;
  }

  function handleTextControlsClick(e: any) {
    popoverRef.current!.event = e;
    setTextControlsPopoverOpen(true);
  }

  function calcFontSize(text: string) {
    let fontSize = "";
    switch (text) {
      case "questionText":
        fontSize = `${(fontSizePercentage.percentage / 100) * 22}px`;
        break;
      case "h1":
        fontSize = `${(fontSizePercentage.percentage / 100) * 15}px`;
        break;
      case "h2":
        fontSize = `${(fontSizePercentage.percentage / 100) * 13}px`;
        break;
      case "answerText":
        fontSize = `${(fontSizePercentage.percentage / 100) * 17}px`;
        break;
    }

    return fontSize;
  }

  function isCaseStudyScoreInitialized() {
    return questionResponses[currentQuestionIndex].caseStudyAnswer.score != -1;
  }

  function handleConfirmation() {
    const copy = [...questionResponses];

    if (currentQuestion.type === "Case Study") {
      if (isCaseStudyScoreInitialized()) {
        copy[currentQuestionIndex].isConfirmed = true;
        updateQuizAttemptInStore(copy[currentQuestionIndex]);
        setQuestionResponses(copy);
      } else {
        setTimeout(() => {
          anchorRef.current[currentQuestionIndex].scrollIntoView({ behavior: "smooth" });
        }, 100);

        copy[currentQuestionIndex].caseStudyAnswer.score = 0;
        setQuestionResponses(copy);
      }
    } else {
      copy[currentQuestionIndex].isConfirmed = true;
      updateQuizAttemptInStore(copy[currentQuestionIndex]);
      setQuestionResponses(copy);
    }
  }

  function getFinishButton() {
    if (checkIfQuestionIsConfirmed() && currentQuestionIndex === questionSet.length - 1) {
      return { handler: () => history.replace(getPrevRoute(location.pathname)) };
    } else {
      return undefined;
    }
  }

  function getMiddleButton() {
    if (currentQuestion.type === "Multi Select" || currentQuestion.type === "Case Study") {
      let text = "Confirm";
      if (checkIfQuestionIsConfirmed()) {
        text = "";
      } else if (
        currentQuestion.type === "Case Study" &&
        !checkIfQuestionIsConfirmed() &&
        isCaseStudyScoreInitialized()
      ) {
        text = "Confirm score";
      } else if (currentQuestion.type === "Case Study" && checkIfQuestionIsConfirmed()) {
        text = "";
      }
      return {
        // text: isCaseStudyScoreInitialized() ? "Confirm my score" : "Confirm",
        text: text,
        handler: handleConfirmation,
        disabled: checkIfQuestionIsConfirmed(),
      };
    } else {
      return undefined;
    }
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className={getBookClassBg(bookID)}>
          <IonTitle style={{ fontFamily: "noto-bold" }}>
            Q{currentQuestionIndex + 1} / Q{questionSet.length}
          </IonTitle>
          <IonButtons slot="start">
            <CustomBackButton url={location.pathname} />
          </IonButtons>
          <IonButtons slot="end">
            <IonButton className="textControlsBtn" onClick={handleTextControlsClick}>
              Aa
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent scrollY={false}>
        <Swiper
          onInit={swiper => setSwiperRef(swiper)}
          id="quizQuestionsSwiper"
          onSlideChange={swiper => setCurrentQuestionIndex(swiper.activeIndex)}
          initialSlide={initialSlideIndex}
          allowTouchMove={false}
        >
          {questionSet.map((question, qIndex) => {
            return (
              <SwiperSlide key={qIndex}>
                <div className="swiperInnerWrapper">
                  <h2 style={{ fontSize: calcFontSize("h2") }}>Module {currentModule.number}</h2>
                  <h1 style={{ fontSize: calcFontSize("h1") }}>{currentModule.name}</h1>
                  <div id="divider" />
                  <p
                    style={{ fontSize: calcFontSize("questionText") }}
                    dangerouslySetInnerHTML={{ __html: question.text.replaceAll("\n", "<br/>") }}
                  />
                  {question.type === "Case Study" ? (
                    <>
                      {isCaseStudyScoreInitialized() && (
                        <>
                          <div className="caseStudyLabel">Correct Answer</div>
                          <div
                            id="caseStudyCorrectAnswer"
                            dangerouslySetInnerHTML={{ __html: question.answers[0].text.replaceAll("\n", "<br/>") }}
                          />
                          <div className="caseStudyLabel">Your Answer</div>
                        </>
                      )}
                      <>
                        <IonTextarea
                          value={questionResponses[currentQuestionIndex].caseStudyAnswer.text}
                          disabled={isCaseStudyScoreInitialized()}
                          autoGrow
                          inputmode="text"
                          rows={6}
                          maxlength={2000}
                          onIonChange={e => {
                            const copy = [...questionResponses];
                            copy[currentQuestionIndex].caseStudyAnswer.text = e.detail.value!;
                            setQuestionResponses(copy);
                          }}
                          className="caseStudyInput"
                        />
                        {isCaseStudyScoreInitialized() && (
                          <>
                            <div style={{ display: "flex" }}>
                              <div className="caseStudyLabel">{`Your mark: ${questionResponses[currentQuestionIndex].caseStudyAnswer.score} / ${currentQuestion.maxScore}`}</div>
                            </div>
                            <IonRange
                              snaps
                              mode="ios"
                              step={1}
                              min={0}
                              max={currentQuestion.maxScore}
                              value={questionResponses[currentQuestionIndex].caseStudyAnswer.score}
                              disabled={questionResponses[currentQuestionIndex].isConfirmed}
                              onIonChange={e => {
                                if (!checkIfQuestionIsConfirmed()) {
                                  const copy = [...questionResponses];
                                  copy[currentQuestionIndex].caseStudyAnswer.score = e.detail.value! as number;
                                  setQuestionResponses(copy);
                                }
                              }}
                            >
                              <IonLabel slot="start">{"0"}</IonLabel>
                              <IonLabel slot="end">{`${currentQuestion.maxScore}`}</IonLabel>
                            </IonRange>
                          </>
                        )}
                      </>
                    </>
                  ) : (
                    // multi choice answer options
                    question.answers.map((answer, aIndex) => {
                      return (
                        <AnswerBox
                          key={aIndex}
                          fontSize={calcFontSize("answerText")}
                          question={question}
                          answer={answer}
                          handleSelection={() => handleMultiChoiceSelection(answer.text)}
                          selected={
                            questionResponses[currentQuestionIndex].multiChoiceAnswers.findIndex(
                              a => a.text === answer.text
                            ) !== -1
                          }
                          isQuestionConfirmed={checkIfQuestionIsConfirmed()}
                        />
                      );
                    })
                  )}
                  <div ref={el => (anchorRef.current[qIndex] = el!)} />
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
        <SwiperControls
          swiperRef={swiperRef}
          showPrev={questionSet[currentQuestionIndex - 1] !== undefined}
          showNext={questionSet[currentQuestionIndex + 1] !== undefined && checkIfQuestionIsConfirmed()}
          finishButton={getFinishButton()}
          middleButton={getMiddleButton()}
        />
        <TextControlsPopover
          isOpen={textControlsPopoverOpen}
          popoverRef={popoverRef}
          handleFontSizeDecrease={() => dispatch(decreaseFontSizePercentage())}
          handleFontSizeIncrease={() => dispatch(increaseFontSizePercentage())}
          handleDismiss={() => setTextControlsPopoverOpen(false)}
        />
      </IonContent>
    </IonPage>
  );
};

export default Quiz;
