import React, { useContext } from "react";
import { IonRouterOutlet, setupIonicReact, IonIcon, IonLabel, IonTabBar, IonTabButton, IonTabs } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Route, Redirect } from "react-router-dom";
import { AppContext } from "domain/AppContextProvider";
import TabHighlight from "components/TabHighlight/TabHighlight";
import ChapterContent from "pages/ChapterContent/ChapterContent";
import ChapterList from "pages/ChapterList/ChapterList";
import BulletinsTab from "pages/Tabs/BulletinsTab/BulletinsTab";
import DashboardTab from "pages/Tabs/DashboardTab/DashboardTab";
import ModulesTab from "pages/Tabs/ModulesTab/ModulesTab";
import QuizzesTab from "pages/Tabs/QuizzesTab/QuizzesTab";
import QuizOverview from "pages/QuizOverview/QuizOverview";
import Quiz from "pages/Quiz/Quiz";
import QuizPreviousResultsList from "pages/QuizPreviousResults/QuizPreviousResultsList";
import QuizPreviousResultsAnswers from "pages/QuizPreviousResultsAnswers/QuizPreviousResultsAnswers";
import Login from "pages/Login/Login";
import ResetPassword from "pages/ResetPassword/ResetPassword";
import PageNotFound from "pages/PageNotFound/PageNotFound";
import BulletinContent from "pages/BulletinContent/BulletinContent";
import Register from "pages/Register/Register";

setupIonicReact();

const NavRouter: React.FC<{}> = ({ children }) => {
  const { appContext } = useContext(AppContext);

  let userExists = appContext.user;

  return (
    <IonReactRouter>
      <IonTabs>
        <IonRouterOutlet mode="ios">
          {/* default */}
          <Route exact path="/" render={() => (userExists ? <Redirect to="/dashboard" /> : <Redirect to="/login" />)} />
          {/* login */}
          <Route exact path="/login" render={() => (userExists ? <Redirect to="/dashboard" /> : <Login />)} />
          <Route
            exact
            path="/login/reset-password"
            render={() => (userExists ? <Redirect to="/dashboard" /> : <ResetPassword />)}
          />
          <Route exact path="/register" render={() => (userExists ? <Redirect to="/dashboard" /> : <Register />)} />
          {/* main tabs */}
          <Route exact path="/dashboard" render={() => (userExists ? <DashboardTab /> : <Redirect to={"/login"} />)} />
          <Route exact path="/modules" render={() => (userExists ? <ModulesTab /> : <Redirect to={"/login"} />)} />
          <Route exact path="/quizzes" render={() => (userExists ? <QuizzesTab /> : <Redirect to={"/login"} />)} />
          <Route exact path="/bulletins" render={() => (userExists ? <BulletinsTab /> : <Redirect to={"/login"} />)} />
          {/* other routes within tabs */}
          <Route
            exact
            path="/modules/module-:moduleNumber"
            render={() => (userExists ? <ChapterList /> : <Redirect to={"/login"} />)}
          />
          <Route
            exact
            path="/modules/module-:moduleNumber/chapter-content"
            render={() => (userExists ? <ChapterContent /> : <Redirect to={"/login"} />)}
          />
          <Route
            exact
            path="/quizzes/overview-module-:moduleNumber"
            render={() => (userExists ? <QuizOverview /> : <Redirect to={"/login"} />)}
          />
          <Route
            exact
            path="/quizzes/overview-module-:moduleNumber/quiz-attempt"
            render={() => (userExists ? <Quiz /> : <Redirect to={"/login"} />)}
          />
          <Route
            exact
            path="/quizzes/overview-module-:moduleNumber/previous-results"
            render={() => (userExists ? <QuizPreviousResultsList /> : <Redirect to={"/login"} />)}
          />
          <Route
            exact
            path="/quizzes/overview-module-:moduleNumber/previous-results/result-:attemptIndex"
            render={() => (userExists ? <QuizPreviousResultsAnswers /> : <Redirect to={"/login"} />)}
          />
          <Route
            exact
            path="/bulletins/bulletin-:bulletinId"
            render={() => (userExists ? <BulletinContent /> : <Redirect to={"/login"} />)}
          />
          {/* page not found page */}
          <Route path="" render={() => <PageNotFound />} />
        </IonRouterOutlet>
        {/* tab bar */}
        <IonTabBar hidden={!userExists || appContext.viewingChapterContent} mode="ios" slot="bottom">
          <IonTabButton tab="dashboard" href="/dashboard">
            <TabHighlight />
            <IonIcon src={"./assets/svgs/Tab_Dashboard_Inactive.svg"} />
            <IonLabel>Dashboard</IonLabel>
          </IonTabButton>
          <IonTabButton tab="modules" href="/modules">
            <TabHighlight />
            <IonIcon icon={"./assets/svgs/Tab_Modules_Inactive.svg"} />
            <IonLabel>Modules</IonLabel>
          </IonTabButton>
          <IonTabButton tab="quizzes" href="/quizzes">
            <TabHighlight />
            <IonIcon icon={"./assets/svgs/Tab_Quiz_Inactive.svg"} />
            <IonLabel>Quizzes</IonLabel>
          </IonTabButton>
          <IonTabButton tab="bulletins" href="/bulletins">
            <TabHighlight />
            <IonIcon icon={"./assets/svgs/Tab_Bulletins_Inactive.svg"} />
            <IonLabel>Bulletins</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </IonReactRouter>
  );
};

export default React.memo(NavRouter);
