import React, { useContext } from "react";
import { IonItem } from "@ionic/react";
import { Module } from "domain/Interfaces";
import { useAppSelector } from "hooks/customReduxHooks";
import { AppContext } from "domain/AppContextProvider";
import "./ModuleListItem.css";

const ModuleListItem: React.FC<{ module: Module; isLastItem: boolean; link: string }> = ({
  module,
  isLastItem,
  link,
}) => {
  const { appContext } = useContext(AppContext);
  const { progress } = useAppSelector(state => state);

  const bookID = appContext.bookID;

  const getModuleStatus = () => {
    const key = bookID === 1 ? "rhFinishedChapters" : "ptFinishedChapters";

    let foundCount = 0;
    module.chapters.forEach(c => {
      const searchStr = `${module.number}-${c.number}`;
      if (progress.progress[key].includes(searchStr)) {
        foundCount++;
      }
    });

    if (foundCount == 0) {
      return "Not Started";
    } else if (foundCount > 0 && foundCount < module.chapters.length) {
      return "In Progress";
    } else {
      return "Finished";
    }
  };

  const getStatusClass = (): string => {
    const status = getModuleStatus();

    switch (status) {
      case "Not Started":
        return "status-not-started";
      case "In Progress":
        return "status-in-progress";
      case "Finished":
        return "status-finished";
      default:
        return "";
    }
  };

  return (
    <IonItem lines={isLastItem ? "none" : "full"} id="moduleListItem" routerLink={link}>
      <div style={{ height: "initial" }}>
        <h2>Module {module.number}</h2>
        <h1>{module.name}</h1>
        <h3 className={getStatusClass()}>{getModuleStatus()}</h3>
      </div>
    </IonItem>
  );
};

export default ModuleListItem;
