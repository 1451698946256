import { IonContent, IonHeader, IonPage, IonSpinner, IonToolbar } from "@ionic/react";
import "./LoadingPage.css";

const LoadingPage: React.FC<{ text: string }> = ({ text }) => {
  return (
    <IonPage>
      <IonContent scrollY={false} id="loadingPageContainer">
        <div>
          <IonSpinner />
          <h1>{text}</h1>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default LoadingPage;
