import { LastReadChapters, ThemeSelectionOption } from "domain/Interfaces";

// Airtable api key
export const AT_API_KEY = "keywtvWj9DBY07iiw";

// export const EOS_SERVER_BASE_URL = "http://localhost:3066/eos";
export const EOS_SERVER_BASE_URL = "https://class.aimernginx.co.uk/eos";
export const EOS_SERVER_LOGIN = "/login";
export const EOS_SERVER_GET_USER_DETAILS = "/getUserDetails";
export const EOS_SERVER_REGISTER = "/register";
export const EOS_SERVER_BULLETINS = "/newsFeed";
export const EOS_SERVER_GET_QUIZ_ATTEMPTS = "/getQuizResults";
export const EOS_SERVER_ADD_QUIZ_ATTEMPT = "/addQuizResult";
export const EOS_SERVER_GET_LATEST_BOOK_CONTENT = "/getLatestDataAllQuestions";
export const EOS_SERVER_GET_BOOKMARKS = "/getBookmarks";
export const EOS_SERVER_ADD_BOOKMARK = "/addBookmark";
export const EOS_SERVER_DEL_BOOKMARK = "/delBookmark";
export const EOS_SERVER_GET_PROGRESS = "/getProgress";
export const EOS_SERVER_UPDATE_PROGRESS = "/updateProgress";
export const EOS_SERVER_GENERATE_CERTIFICATE = "/generateCertificateWithPicture";
export const EOS_SERVER_REQUEST_ACCOUNT_DELETION = "/requestAccountDeletion";
export const EOS_SERVER_CREATE_SUBSCRIBER = "/createSubscriber";

// IAP product ids
export const APPLE_PRODUCT_IDS = [
  "uk.co.classprofessional.eos.passenger.1month",
  "uk.co.classprofessional.eos.passenger.12month",
  "uk.co.classprofessional.eos.roadhaulage.1month",
  "uk.co.classprofessional.eos.roadhaulage.12month",
];
export const GOOGLE_PRODUCT_IDS = [
  "eos.passenger.android.1month",
  "eos.passenger.android.12month",
  "eos.roadhaulage.android.1month",
  "eos.roadhaulage.android.12month",
];

export const SUPPORT_EMAIL_ADDRESS = "info@eostraining.com";

export const STORAGE_KEY_USER = "USER";
export const STORAGE_KEY_BOOK_SELECTION = "BOOK_SELECTION";
export const STORAGE_KEY_THEME_SELECTION = "THEME_SELECTION";
export const STORAGE_KEY_LAST_READ_CHAPTERS = "LAST_READ_CHAPTERS";

export const QUIZ_PASS_PERCENTAGE = 0.75;
export const CASE_STUDY_PASS_PERCENTAGE = 0.75;

export const THEME_SELECTION_DEFAULT = ThemeSelectionOption.Auto;
export const FONT_SIZE_PERCENTAGE_DEFAULT = 100;
export const LAST_READ_CHAPTERS_DEFAULT: LastReadChapters = {
  rh: { module: 0, chapter: 0 },
  pt: { module: 0, chapter: 0 },
};

export const FONT_SIZE_PERCENTAGE_SCALE_AMOUNT = 5;

export const AT_QUIZ_QUESTIONS_BASE_ID = "appV0mUmxfDBCbaLx";
