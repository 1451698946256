import { IonSpinner } from "@ionic/react";
import "./OverviewButton.css";

const OverviewButton: React.FC<{
  text: string;
  clickHandler: () => void;
  fill?: boolean;
  disabled?: boolean;
}> = ({ text, clickHandler, fill = false, children, disabled = false }) => {
  return (
    <button
      disabled={disabled}
      className={fill ? "fill overviewButton" : "outline overviewButton"}
      onClick={clickHandler}
    >
      {text === "" ? children : text}
    </button>
  );
};

export default OverviewButton;
