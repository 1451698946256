import { IonButton, IonButtons, IonContent, IonHeader, IonModal, IonTitle, IonToolbar } from "@ionic/react";
import { AppContext } from "domain/AppContextProvider";
import { Module } from "domain/Interfaces";
import { useContext } from "react";
import { getBookClassBg, getCurrentBookFromBookID } from "utils/Utils";
import "./QuizInfoModal.css";

const QuizInfoModal: React.FC<{ isOpen: boolean; handleClose: () => void; module: Module }> = ({
  isOpen,
  handleClose,
  module,
}) => {
  const { appContext } = useContext(AppContext);
  const books = appContext.books;
  const bookID = appContext.bookID;
  const currentBook = getCurrentBookFromBookID(books, bookID);

  return (
    <IonModal handle={false} className="quizInfoModal" isOpen={isOpen} onWillDismiss={handleClose}>
      <IonHeader>
        <IonToolbar id="quizInfoModalToolbar" className={getBookClassBg(bookID)}>
          <IonButtons slot="start">
            <IonButton style={{ visibility: "hidden" }}>Close</IonButton>
          </IonButtons>
          <IonTitle class="ion-text-center">Quiz Info</IonTitle>
          <IonButtons slot="end">
            <IonButton id="modalClose" onClick={handleClose}>
              Close
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="modalContent">
          <h1>Quizzes and Case Studies</h1>
          <p>This Module consists of a quiz to help you test your knowledge.</p>
          <p>
            Quizzes can be attempted indefinitely and to pass a Module you will need to get 75% correct answers. The
            label underneath the title of the Module indicates if you have passed (Completed) or if you have not yet
            reached the pass mark (In Progress).
          </p>
          <p>
            Case studies are self-assessed. Write your answer and tap Confirm to view the correct answer. Compare what
            you have written and give yourself a mark accordingly.
          </p>
          <p>
            When you have reached the pass mark (75%) for the Module, you will be able to generate a certificate which
            can be emailed to yourself for print
          </p>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default QuizInfoModal;
