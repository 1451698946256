import { IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, useIonToast } from "@ionic/react";
import CustomBackButton from "components/CustomBackButton/CustomBackButton";
import GenerateCertificateButton from "components/GenerateCertificateButton/GenerateCertificateButton";
import OverviewButton from "components/OverviewButton/OverviewButton";
import QuizInfoModal from "components/QuizInfoModal/QuizInfoModal";
import { AppContext } from "domain/AppContextProvider";
import { QuizAttempt } from "domain/Interfaces";
import { addQuizAttempt } from "features/quizAttempts/quizAttemptsSlice";
import { useAppDispatch, useAppSelector } from "hooks/customReduxHooks";
import moment from "moment";
import { useContext, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import {
  getBookClassBg,
  getCurrentBookFromBookID,
  getQuizAttemptsForModule,
  getLatestQuizAttemptForModule,
  getLatestPassedQuizAttemptForModule,
} from "utils/Utils";
import "./QuizOverview.css";

const QuizOverview: React.FC<{}> = () => {
  const { moduleNumber } = useParams<{ moduleNumber: string }>();
  const location = useLocation();
  const history = useHistory();

  const dispatch = useAppDispatch();
  const { appContext } = useContext(AppContext);
  const { allQuizAttempts } = useAppSelector(state => state);

  const [presentToast] = useIonToast();

  const books = appContext.books;
  const bookID = appContext.bookID;
  const currentBook = getCurrentBookFromBookID(books, bookID);
  const currentModule = currentBook.modules.find(mod => mod.number === parseInt(moduleNumber))!;
  const filteredQuizAttempts = getQuizAttemptsForModule(bookID, currentModule.number, allQuizAttempts.attempts);
  const latestQuizAttempt = getLatestQuizAttemptForModule(filteredQuizAttempts);
  const hasPassedAttempt = filteredQuizAttempts.some(attempt => attempt.hasPassed);
  const latestPassedAttempt = getLatestPassedQuizAttemptForModule(filteredQuizAttempts);

  const [showQuizInfoModal, setShowQuizInfoModal] = useState(false);

  const getStatusLabel = () => {
    if (!filteredQuizAttempts.length) {
      return <span style={{ color: "var(--status-label-not-started)" }}>Not Started</span>;
    }

    return (
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <img
          style={{ width: "24px", height: "24px", marginRight: "4px" }}
          src={
            hasPassedAttempt ? "./assets/images/Icon_Completed_Green@3x.png" : "./assets/images/Icon_In_Progress@3x.png"
          }
        />
        <span
          style={{
            color: hasPassedAttempt ? "var(--status-label-passed)" : "var(--status-label-in-progress)",
          }}
        >
          {hasPassedAttempt ? "Completed" : "In Progress"}
        </span>
      </div>
    );
  };

  const startNewQuiz = () => {
    const newQuizAttempt: QuizAttempt = {
      id: "",
      bookID: bookID,
      moduleNumber: currentModule.number,
      score: 0,
      maxScore: getNumberOfNonCaseStudyQuestions() + getNumberOfCaseStudyQuestions(),
      dateTimeStarted: moment().toISOString(),
      isFinished: false,
      hasPassed: false,
      questionsAnswered: [],
    };

    // update store
    dispatch(addQuizAttempt({ attempt: newQuizAttempt, userId: appContext.user?.code! }));

    // begin quiz
    history.push(`${location.pathname}/quiz-attempt`, {
      moduleNumberForQuiz: currentModule.number,
    });
  };

  const resumeQuiz = () => {
    history.push(`${location.pathname}/quiz-attempt`, {
      moduleNumberForQuiz: currentModule.number,
    });
  };

  const getNumberOfNonCaseStudyQuestions = () => {
    const questions = currentModule.quizQuestions.filter(q => q.type !== "Case Study");
    return questions.length < 10 ? questions.length : 10;
  };

  const getNumberOfCaseStudyQuestions = () => {
    return currentModule.quizQuestions.filter(q => q.type === "Case Study").length;
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className={getBookClassBg(bookID)}>
          <IonTitle>Overview</IonTitle>
          <IonButtons slot="start">
            <CustomBackButton url={location.pathname} />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent scrollY={false}>
        <div className="contentWrapper">
          <div id="quizOverviewContentContainer">
            <div id="overviewSummaryBox">
              <h2>Module {currentModule?.number}</h2>
              <h1>{currentModule?.name}</h1>
              <h3>{getStatusLabel()}</h3>
              <p>{`This quiz contains ${getNumberOfNonCaseStudyQuestions()} multiple choice questions and ${getNumberOfCaseStudyQuestions()} self-assessed case study questions.`}</p>
              <h6 id="learnMorelink" onClick={() => setShowQuizInfoModal(true)}>
                Learn more
              </h6>
            </div>
            {/* <div>{JSON.stringify(latestQuizAttempt)}</div> */}
            <div id="boxItemDivider" />
            <div id="overviewQuizBox">
              <h1>Quiz</h1>
              {latestQuizAttempt != null && (
                <>
                  <h2>Last attempt:</h2>
                  <h3>
                    <span
                      className={`bestAttemptScore ${
                        latestQuizAttempt.hasPassed ? "passScoreColour" : "inProgressScoreColour"
                      }`}
                    >
                      {latestQuizAttempt.score}
                    </span>{" "}
                    / {latestQuizAttempt.maxScore}
                  </h3>
                </>
              )}
              <div>
                {latestQuizAttempt == null || latestQuizAttempt.isFinished ? (
                  <OverviewButton text="Start quiz" fill clickHandler={startNewQuiz} />
                ) : (
                  <>
                    <OverviewButton text="Resume" fill clickHandler={resumeQuiz} />
                    <OverviewButton text="Restart quiz" fill clickHandler={startNewQuiz} />
                  </>
                )}
                {hasPassedAttempt && (
                  <GenerateCertificateButton
                    user={appContext.user!}
                    module={currentModule}
                    book={currentBook}
                    latestPassedAttempt={latestPassedAttempt!}
                    handleError={() =>
                      presentToast({
                        message: "Failed to generate certificate, check your network connection.",
                        mode: "ios",
                        duration: 2500,
                      })
                    }
                  />
                )}
                {filteredQuizAttempts.length > 0 && (
                  <OverviewButton
                    text="View previous attempts"
                    clickHandler={() => history.push(`${location.pathname}/previous-results`)}
                  />
                )}
              </div>
            </div>
            <div id="boxItemDivider" />
          </div>
        </div>
        <QuizInfoModal
          isOpen={showQuizInfoModal}
          handleClose={() => setShowQuizInfoModal(false)}
          module={currentModule}
        />
      </IonContent>
    </IonPage>
  );
};

export default QuizOverview;
