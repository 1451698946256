import { Capacitor } from "@capacitor/core";
import {
  QuizQuestionAnswer,
  Book,
  BookID,
  QuizAttempt,
  Bulletin,
  LiveQuizQuestion,
  Progress,
  Module,
  User,
  Subscription,
  AppEntitlementsResponse,
} from "domain/Interfaces";
import moment from "moment";
import { IapService } from "services/IapService";
import { ServerService } from "services/ServerService";

export const shuffleAnswers = (array: QuizQuestionAnswer[]): QuizQuestionAnswer[] => {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
};

export const getBookClassBg = (bookID: BookID): string => {
  switch (bookID) {
    case 1:
      return "roadHaulageBg";
    case 2:
      return "passengerTransportBg";
    default:
      return "roadHaulageBg";
  }
};

export const formatDate = (dateTime: string): string => {
  // format example: 3 Jan 2022 at 09:41
  const formattedDateTime = moment(dateTime).format("DD MMM YYYY");
  return formattedDateTime;
};

export const formatDateTime = (dateTime: string): string => {
  // format example: 3 Jan 2022 at 09:41
  const formattedDateTime = moment(dateTime).format("DD MMM YYYY [at] H:mm");
  return formattedDateTime;
};

// adds mark tags to html string
export const addHighlightsBasedOnSearch = (originalStr: string, searchStr: string): string => {
  if (searchStr === "") {
    return originalStr;
  }

  let newStr = originalStr;
  const regex = new RegExp(searchStr, "gi");
  const indices = [];
  let res: RegExpExecArray | null;
  while ((res = regex.exec(originalStr))) {
    indices.push(res.index);
  }

  indices.reverse().forEach(index => {
    newStr =
      newStr.slice(0, index) +
      "<mark>" +
      newStr.slice(index, index + searchStr.length) +
      "</mark>" +
      newStr.slice(index + searchStr.length);
  });

  return newStr;
};

export const getBookClassBgColour = (bookID: BookID): string => {
  switch (bookID) {
    case 1:
      return "roadHaulageBgc";
    case 2:
      return "passengerTransportBgc";
    default:
      return "roadHaulageBgc";
  }
};

// gets previous route (route before last '/'). example: returns 'home/report' from 'home/report/report-1'
export const getPrevRoute = (url: string): string => {
  const routes = url.split("/");
  routes.pop();
  let prevRoute = "";
  routes.forEach((route, index) => (prevRoute += index === routes.length - 1 ? route : `${route}/`));

  return prevRoute;
};

export const trimHtmlTags = (str: string): string => {
  return str.replace(/(<([^>]+)>)/gi, "");
};

export const getCurrentBookFromBookID = (books: Book[], bookID: BookID): Book => {
  return books.find(book => book.bookID === bookID)!;
};

// returns the latest quiz attempt, otherwise return null
export const getLatestQuizAttemptForModule = (filteredAttempts: QuizAttempt[]): QuizAttempt | null => {
  if (filteredAttempts.length === 0) {
    return null;
  } else {
    const latestAttempt = filteredAttempts.reduce((a, b) => (a.dateTimeStarted > b.dateTimeStarted ? a : b));
    return latestAttempt;
  }
};

// returns the latest passed quiz attempt, otherwise return null
export const getLatestPassedQuizAttemptForModule = (filteredAttempts: QuizAttempt[]): QuizAttempt | null => {
  if (filteredAttempts.length === 0) {
    return null;
  } else {
    const passedAttempts = filteredAttempts.filter(a => a.hasPassed);
    if (passedAttempts.length === 0) {
      return null;
    } else {
      const latestAttempt = passedAttempts.reduce((a, b) => (a.dateTimeStarted > b.dateTimeStarted ? a : b));
      return latestAttempt;
    }
  }
};

export const getLatestBulletin = (bulletins: Bulletin[]): Bulletin | null => {
  if (!bulletins.length) return null;
  return bulletins.reduce((a, b) => (a.date > b.date ? a : b));
};

export const getStatusTextForQuizAttemptsInModule = (quizAttempts: QuizAttempt[]): string => {
  if (!quizAttempts.length) {
    return "Not Started";
  } else if (quizAttempts.some(a => a.hasPassed)) {
    return "Complete";
  } else {
    return "In Progress";
  }
};

// checks user object for a valid sub
export const validateUser = (user: User): boolean => {
  const ptSubValidation = isSubValid(user.ptSub);
  const rhSubValidation = isSubValid(user.rhSub);

  return ptSubValidation || rhSubValidation;
};

// checks an individual sub for validation
export const isSubValid = (sub: Subscription | null): boolean => {
  if (!sub) return false;

  const now = moment().toISOString();

  // add 1 day to end date so that subscription is valid on the same day as expiry
  return moment(sub.endDate).add(1, "d").isAfter(now);
};

export const getQuizAttemptsForModule = (bookID: BookID, moduleNumber: number, allQuizAttempts: QuizAttempt[]) => {
  return allQuizAttempts.filter(attempt => attempt.bookID === bookID && attempt.moduleNumber === moduleNumber);
};

export const getQuizAttemptsForBook = (bookID: BookID, allQuizAttempts: QuizAttempt[]) => {
  return allQuizAttempts.filter(attempt => attempt.bookID === bookID);
};

export const checkIfChapterIsFinished = (
  bookId: number,
  allProgress: Progress,
  moduleNumber: number,
  chapterNumber: number
) => {
  const key = bookId === 1 ? "rhFinishedChapters" : "ptFinishedChapters";

  if (!allProgress[key].length) return false;

  const index = allProgress[key].findIndex(item => item === `${moduleNumber}-${chapterNumber}`);
  return index !== -1;
};

export const checkIfModuleIsFinished = (bookID: number, allProgress: Progress, module: Module) => {
  const key = bookID === 1 ? "rhFinishedChapters" : "ptFinishedChapters";

  let res = true;
  module.chapters.forEach(c => {
    const searchStr = `${module.number}-${c.number}`;
    if (!allProgress[key].includes(searchStr)) res = false;
  });

  return res;
};

export const getBookName = (book: Book) => {
  if (book.bookID === 1) {
    return "Road Haulage";
  } else {
    return "Passenger Transport";
  }
};

export const displaySubStatus = (sub: Subscription | null): string => {
  if (!sub) return "N/A";
  if (sub.isEOScreated) return "active";
  return `expires ${formatDate(sub.endDate)}${isSubValid(sub) ? "" : " (expired)"}`;
};

// accepts user object and returns a copy of the same user object with the correct subscription information. the user object returned should be saved to context after this function call.
// this function also updates salesforce with the correct expiry date (in the event of renewals).
export const validateSubscriptionForUser = async (user: User, isRestoring: boolean = false) => {
  const userCopy = { ...user };

  const sfSubsResponse = await ServerService.getSubsForUser(user);
  let inAppSubsResponse: AppEntitlementsResponse = { success: true, subs: [] };
  if (Capacitor.getPlatform() === "android") {
    inAppSubsResponse = await IapService.getEntitlmentsFromGoogle();
  } else if (Capacitor.getPlatform() === "ios") {
    inAppSubsResponse = await IapService.getEntitlmentsFromApple();
  }

  const allSubs = [...sfSubsResponse.data, ...inAppSubsResponse.subs];

  // don't update user object if retrieval of subs from salesforce and the in-app store fails, and user is attempting to restore subs.
  if (!sfSubsResponse.success && !inAppSubsResponse.success && isRestoring) return userCopy;

  // add pt or rh sub loaded from local storage if retrieval of subs from salesforce
  // and Apple/Google fails (suggesting the device might be offline)
  // TODO there could be a problem where sfSubs request succeeds, but inAppSubs request fails (or vice versa)
  // TODO another issue could occur when a user cancels their subscription (outside of the app), and the app/salesforce doesn't know about it so the subscription is still "valid"
  if (!sfSubsResponse.success && !inAppSubsResponse.success) {
    if (userCopy.ptSub) {
      allSubs.push(userCopy.ptSub);
    }
    if (userCopy.rhSub) {
      allSubs.push(userCopy.rhSub);
    }
  }

  await updateSalesforceSubIfNecessary("pt", userCopy, inAppSubsResponse.subs, sfSubsResponse.data);
  await updateSalesforceSubIfNecessary("rh", userCopy, inAppSubsResponse.subs, sfSubsResponse.data);

  userCopy.ptSub = getLatestValidSubForType("pt", allSubs);
  userCopy.rhSub = getLatestValidSubForType("rh", allSubs);

  return userCopy;
};

// update salesforce with IAP subs if neccessary (in cases where they don't already exist, or exist, but require an updated date - i.e. renewals)
const updateSalesforceSubIfNecessary = async (
  subType: "pt" | "rh",
  currentUser: User,
  inAppSubs: Subscription[],
  sfSubs: Subscription[]
) => {
  if (!inAppSubs.length) return;

  const latestIAPsub = getLatestValidSubForType(subType, inAppSubs);
  if (latestIAPsub) {
    const iapSubsOnSf = sfSubs.filter(s => !s.isEOScreated);
    const replacement = getLatestValidSubForType(subType, iapSubsOnSf);
    if (!replacement || moment(latestIAPsub.endDate).isAfter(replacement.endDate)) {
      console.log("updating SF with sub..", JSON.stringify(latestIAPsub));
      ServerService.createSub(
        currentUser,
        latestIAPsub.productId!,
        latestIAPsub.transactionId!,
        latestIAPsub.endDate,
        latestIAPsub.startDate!
      );
    }
  }
};

export const getLatestValidSubForType = (subType: "pt" | "rh", subs: Subscription[]): Subscription | null => {
  const filteredSubs = subs.filter(s => s.type === subType);

  if (!filteredSubs.length) return null;

  let latestSub: Subscription = filteredSubs[0];
  filteredSubs.forEach(s => {
    if (s.endDate > latestSub.endDate) latestSub = s;
  });

  return isSubValid(latestSub) ? latestSub : null;
};

export const mapGoogleSub = (googleSub: any): Subscription => {
  // convert end date
  const ed = new Date(googleSub.expiryDate).toISOString();

  // convert start date
  var dateString = googleSub.originalStartDate;
  var dateParts = dateString.split(" ");
  var date = dateParts[0].split("-");
  var time = dateParts[1].split(":");
  var sd = new Date(date[2], date[1] - 1, date[0], time[0], time[1]).toISOString();

  const sub: Subscription = {
    endDate: ed,
    type: googleSub.productIdentifier.includes("passenger") ? "pt" : "rh",
    productId: googleSub.productIdentifier,
    transactionId: googleSub.transactionId,
    startDate: sd,
  };

  return sub;
};
