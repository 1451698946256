import { Dispatch } from "react";

export interface AppContextType {
  appContext: AppContextData;
  setAppContext: Dispatch<React.SetStateAction<AppContextData>>;
}

export interface AppContextData {
  isInitialised: boolean;
  user: User | null;
  books: Book[];
  bookID: BookID;
  theme: ThemeSelectionOption;
  appInfo: AppInfo | null;
  deviceInfo: DeviceInfo | null;
  bulletins: Bulletin[];
  lastReadChapters: LastReadChapters;
  viewingChapterContent: boolean;
}

export interface LastReadChapters {
  rh: { module: number; chapter: number };
  pt: { module: number; chapter: number };
}

export interface AppInfo {
  build: string;
  id: string;
  name: string;
  version: string;
}

export interface DeviceInfo {
  manufacturer: string;
  model: string;
  name?: string;
  operatingSystem: string;
  osVersion: string;
  platform: string;
}

export interface Bookmark {
  id: string; // new ones will be empty
  bookId: BookID;
  moduleNumber: number;
  chapterNumber: number;
}

export interface User {
  name: string;
  email: string;
  jwt: string;
  code: string;
  ptSub: Subscription | null; // will be null if no sub exists or if a sub exists but it's invalid
  rhSub: Subscription | null; // will be null/undefined if no sub exists or if a sub exists but it's invalid
}

export interface Subscription {
  id?: "";
  endDate: string;
  type: "pt" | "rh"; // pt = passenger transport book access OR rh = road haulage book access
  isEOScreated?: boolean; // eos = sub created by eos (i.e purchased through EOS, not us or in-app).
  productId?: string;
  transactionId?: string;
  startDate?: string;
}

export interface AppEntitlementsResponse {
  success: boolean;
  subs: Subscription[];
}

export interface SubProduct {
  productId: string;
  price: string;
  displayName: string;
  description: string;
}

export interface Book {
  bookID: BookID;
  modules: Module[];
}

export interface Progress {
  // id: string;
  rhFinishedChapters: string[]; // example of item = '1-2' (module 1, chapter 2)
  ptFinishedChapters: string[];
}

export interface Chapter {
  number: number;
  name: string;
  sections: Section[];
}

export interface Section {
  number: number;
  name: string;
  html: string;
}

export interface Module {
  number: number;
  name: string;
  chapters: Chapter[];
  quizQuestions: LiveQuizQuestion[];
  quizUpdateTime: string;
}

export interface Bulletin {
  id: string;
  title: string;
  html: string;
  date: string;
  subtitle: string;
  live: string;
  name: string;
  lastModified: string; // in ISO
}

export interface LoginResponse {
  success: boolean;
  user: User | null;
  message: string;
}

export interface RegisterResponse {
  success: boolean;
  errorMessage: string;
}

export enum BookID {
  Default = 0,
  RoadHaulage = 1, // "Road Haulage",
  PassengerTransport = 2, // "Passenger Transport",
}

export enum ThemeSelectionOption {
  Auto = "Automatic",
  Light = "Light",
  Dark = "Dark",
}

export interface LiveQuizQuestion {
  id: string;
  lastModified: string; // in ISO
  bookID: BookID;
  moduleNumber: number;
  maxScore: number;
  type: string;
  text: string;
  answers: QuizQuestionAnswer[];
}

export interface QuizQuestionAnswer {
  id: string;
  lastModified: string; // in ISO
  isCorrect: boolean;
  text: string;
}

export interface QuizAttempt {
  id: string; // new ones will be empty
  bookID: number;
  moduleNumber: number;
  score: number;
  maxScore: number;
  dateTimeStarted: string;
  isFinished: boolean;
  hasPassed: boolean; // passed when a user has answered all questions and achieved pass mark (see QUIZ_PASS_PERCENTAGE in Constants.ts)
  questionsAnswered: {
    questionNumber: number;
    questionText: string;
    questionType: string; // 'Single Select', 'Multi Select', or 'Case Study'
    // for case study answers:
    caseStudyAnswer: {
      text: string;
      score: number;
    };
    // for 'single select' and 'multi select' answers:
    multiChoiceAnswers: {
      text: string;
      isCorrect: boolean;
    }[];
  }[];
}

export interface QuestionResponse {
  isConfirmed: boolean;
  caseStudyAnswer: { text: string; score: number };
  multiChoiceAnswers: { text: string; isCorrect: boolean }[];
}
