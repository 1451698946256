import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonPage,
  IonSpinner,
  IonToolbar,
  useIonToast,
} from "@ionic/react";
import { TextFieldTypes } from "@ionic/core";
import { useState } from "react";
import { ServerService } from "services/ServerService";
import { useHistory } from "react-router";
import LoginTermsAndConditions from "components/LoginTermsAndConditions/LoginTermsAndConditions";
import "./Register.css";

const Register = () => {
  const history = useHistory();

  const [registering, setRegistering] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordInputType, setpasswordInputType] = useState<TextFieldTypes>("password");
  const [error, setError] = useState("");

  const [presentToast] = useIonToast();

  const inputsIncomplete = name === "" || email === "" || password === "";

  const handleRegister = async () => {
    setError("");
    setRegistering(true);

    const res = await ServerService.register(name, email, password);
    if (res.success) {
      presentToast({
        message: "Registered Successfully.",
        mode: "ios",
        duration: 2500,
      });

      setName("");
      setEmail("");
      setPassword("");
      setError("");
      setRegistering(false);
      history.goBack();
    } else {
      const msg = res.errorMessage.includes("Already exists")
        ? "The email address you have entered is already in use, please try logging in or use a different email address"
        : res.errorMessage;
      setError(msg);
      setRegistering(false);
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className="resetPassToolbar">
          <IonButtons>
            <IonBackButton defaultHref="/login" />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent id="registerContent">
        <div className="contentWrapper" style={{ display: "flex", flexDirection: "column" }}>
          <div id="registerContentContainer">
            <div>
              <form>
                <div className="loginTitle">Register</div>
                <div className="inputLabel">Full Name</div>
                <IonInput
                  value={name}
                  className="userInput"
                  disabled={registering}
                  type="text"
                  onIonChange={e => setName(e.detail.value!)}
                />
                <div className="inputLabel">Email</div>
                <IonInput
                  value={email}
                  className="userInput"
                  disabled={registering}
                  type="email"
                  onIonChange={e => setEmail(e.detail.value!)}
                />
                <div className="inputLabel">Password</div>
                <div id="passwordInputContainer">
                  <IonInput
                    value={password}
                    disabled={registering}
                    type={passwordInputType}
                    onIonChange={e => setPassword(e.detail.value!)}
                  />
                  <IonButton
                    fill="clear"
                    onClick={() => setpasswordInputType(prev => (prev === "text" ? "password" : "text"))}
                  >
                    {passwordInputType === "password" ? "Show" : "Hide"}
                  </IonButton>
                </div>
                {error !== "" && (
                  <div id="loginErrorMsg">
                    <IonIcon id="loginErrorMsgIcon" icon={"./assets/svgs/Button_Error.svg"} />
                    {error}
                  </div>
                )}
              </form>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <IonButton
                  className="loginBtn"
                  disabled={inputsIncomplete}
                  style={{ "--opacity": inputsIncomplete ? "0.4" : "1" }}
                  onClick={handleRegister}
                >
                  {registering ? <IonSpinner /> : "Register"}
                </IonButton>
              </div>
            </div>
            <LoginTermsAndConditions />
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Register;
