import { IonButton, IonSpinner, useIonLoading } from "@ionic/react";
import { Subscriptions } from "capacitor-subscriptions";
import { AppContext } from "domain/AppContextProvider";
import { BookID, SubProduct, Subscription } from "domain/Interfaces";
import { RefObject, useContext, useEffect, useState } from "react";
import { ServerService } from "services/ServerService";
import { APPLE_PRODUCT_IDS, GOOGLE_PRODUCT_IDS } from "utils/Constants";
import { Capacitor } from "@capacitor/core";
import ContactSupport from "components/ContactSupport/ContactSupport";
import moment from "moment";
import { mapGoogleSub, validateSubscriptionForUser } from "utils/Utils";

const SubscriptionsList: React.FC<{ modalRef?: RefObject<HTMLIonModalElement> }> = ({ modalRef }) => {
  const { appContext, setAppContext } = useContext(AppContext);

  const [loadingProducts, setLoadingProducts] = useState(false);
  const [loadingProductsError, setLoadingProductsError] = useState(false);
  const [subProducts, setSubProducts] = useState<SubProduct[]>([]);

  const [presentOverlay, dismissOverlay] = useIonLoading();

  useEffect(() => {
    getIapProducts();
  }, []);

  const getIapProducts = async () => {
    setLoadingProducts(true);
    setLoadingProductsError(false);

    const products: SubProduct[] = [];
    const productIds = Capacitor.getPlatform() === "ios" ? APPLE_PRODUCT_IDS : GOOGLE_PRODUCT_IDS;
    for (const id of productIds) {
      try {
        const response = await Subscriptions.getProductDetails({ productIdentifier: id });
        console.log(`IAP product found: ${id}`, response.data);
        if (response.data) {
          products.push({
            productId: id,
            displayName: response.data.displayName,
            description: response.data.description,
            price: response.data.price,
          });
        }
      } catch (error) {
        console.log("Failed to get iap products");
        setLoadingProductsError(true);
      }
    }

    setSubProducts(products);
    setLoadingProducts(false);
  };

  const handleApplePurchase = async (productId: string) => {
    const purchaseProductResponse = await Subscriptions.purchaseProduct({ productIdentifier: productId });

    /**
     * response codes and matching messages:
     * -1: Incompatible with web
     * 0: Successfully purchased product. Successful new purchase OR the user clicked the button despite already owning the product.
     * 1: Could not find a product matching the given productIdentifier
     * 2: Product seems to have been purchased but the transaction failed verification
     * 3: User closed the native popover before purchasing
     * 4: Product request made but is currently pending - likely due to parental restrictions
     * 5: An unknown error occurred whilst in the purchasing process
     */
    if (purchaseProductResponse.responseCode === 0) {
      const latestTransactionResponse = await Subscriptions.getLatestTransaction({ productIdentifier: productId });
      const { productIdentifier, transactionId, expiryDate, originalStartDate } = latestTransactionResponse.data!;
      await ServerService.createSub(appContext.user!, productIdentifier, transactionId, expiryDate, originalStartDate);

      savePurchase(productIdentifier, {
        endDate: expiryDate,
        type: productIdentifier.includes("passenger") ? "pt" : "rh",
        productId: productIdentifier,
        transactionId: transactionId,
        startDate: originalStartDate,
      });
    }

    await dismissOverlay();
  };

  const savePurchase = (productId: string, newSub: Subscription) => {
    let bid = appContext.bookID;
    const userCopy = { ...appContext.user! };
    if (productId.includes("passenger")) {
      // 0 and 1 = passenger product ids
      bid = BookID.PassengerTransport;
      if (!userCopy.ptSub || (userCopy.ptSub && moment(newSub.endDate).isAfter(userCopy.ptSub.endDate))) {
        userCopy.ptSub = newSub;
      }
    } else {
      // 2 and 3 = road haulage product ids
      bid = BookID.RoadHaulage;
      if (!userCopy.rhSub || (userCopy.rhSub && moment(newSub.endDate).isAfter(userCopy.rhSub.endDate))) {
        userCopy.rhSub = newSub;
      }
    }

    setAppContext(prev => ({ ...prev, user: userCopy, bookID: bid }));
  };

  const handleGooglePurchase = (productId: string) => {
    Subscriptions.addListener("ANDROID-PURCHASE-RESPONSE", async (response: any) => {
      if (response.successful) {
        // get subs from Google
        let subs: any[] = [];
        try {
          const response = await Subscriptions.getCurrentEntitlements();
          if (response.responseCode === 0 && response.data) {
            // data is returned as string for some reason so parse as JSON
            subs = JSON.parse(response.data.toString());
          }
          // get latest sub
          if (subs.length === 0) throw "can't get latest Google sub";
          const purchasedSub = subs.find(s => s.productIdentifier === productId);
          if (purchasedSub) {
            const mappedSub = mapGoogleSub(purchasedSub);
            // savePurchase(productId, mappedSub.endDate);
            savePurchase(productId, mappedSub);
          }
        } catch (error) {
          console.log("error getting subs from Google ", error);
          subs = [];
        }
      }

      await dismissOverlay();
    });

    Subscriptions.purchaseProduct({ productIdentifier: productId });
  };

  const handlePurchase = async (productId: string) => {
    await presentOverlay({ message: "Processing subscription selection..." });

    if (Capacitor.getPlatform() === "ios") {
      handleApplePurchase(productId);
    } else {
      handleGooglePurchase(productId);
    }
  };

  const handleRestorePurchases = async () => {
    await presentOverlay({ message: "Restoring purchases..." });

    const userCopy = await validateSubscriptionForUser(appContext.user!, true);
    setAppContext(prev => ({ ...prev, user: userCopy }));

    await dismissOverlay();
  };

  return (
    <>
      <div className="o-med fs-22 c-prim-50 mt-32">Subscription options</div>
      <div className="n-reg fs-16 mt-8" style={{ color: modalRef ? "black" : "var(--color-black-white-subtle)" }}>
        To use the app, please purchase a subscription below. Please note that unless changed in your subscription
        settings, all subscription options are auto-renewing. Full information on subscriptions can be found below.
      </div>
      {loadingProducts ? (
        <div className="flex jc-center ai-center mt-32">
          <IonSpinner style={{ "--color": "#006b8c" }} />
        </div>
      ) : loadingProductsError ? (
        <div className="flex jc-center ai-center mt-32 c-red">Failed to load subscription options</div>
      ) : subProducts.length === 0 ? (
        <div className="flex jc-center ai-center mt-32 c-red">Subscription options not found</div>
      ) : (
        <>
          <div
            className="n-bold fs-20 mt-12 ml-20"
            style={{ color: modalRef ? "black" : "var(--color-black-white-subtle)" }}
          >
            Passenger Transport
          </div>
          {subProducts
            .filter(s => s.productId.includes("passenger"))
            .map((p, i) => {
              return (
                <IonButton
                  key={i}
                  className={`bgc-prim-btn c-white br-2 mt-16 ba-none ${modalRef ? "mr-20 ml-20" : ""}`}
                  onClick={() => handlePurchase(p.productId)}
                >
                  {`${p.productId.includes("1month") ? "Monthly" : "Annually"} / ${p.price}`}
                </IonButton>
              );
            })}
          <div
            className="n-bold fs-20 mt-12 ml-20"
            style={{ color: modalRef ? "black" : "var(--color-black-white-subtle)" }}
          >
            Road Haulage
          </div>
          {subProducts
            .filter(s => s.productId.includes("road"))
            .map((p, i) => (
              <IonButton
                key={i}
                className={`bgc-prim-btn c-white br-2  mt-16 ba-none ${modalRef ? "mr-20 ml-20" : ""}`}
                onClick={() => handlePurchase(p.productId)}
              >
                {`${p.productId.includes("1month") ? "Monthly" : "Annually"} / ${p.price}`}
              </IonButton>
            ))}
          <IonButton
            fill="outline"
            className={`c-prim-btn br-2 mt-16 ba-none ${modalRef ? "mr-20 ml-20" : ""}`}
            style={{ "--border-color": "#006b8c" }}
            onClick={handleRestorePurchases}
          >
            Restore existing purchases
          </IonButton>
        </>
      )}
      <ContactSupport isForModal={modalRef != undefined} />
      <div className="o-med fs-22 c-prim-50 mt-32">Subscription information</div>
      <div className="mr-10 mb-32" style={{ color: modalRef ? "black" : "var(--color-black-white-subtle)" }}>
        <ul>
          <li>
            {`In-app auto-renewing subscriptions: these are purchased using your ${
              Capacitor.getPlatform() === "ios" ? "iTunes Account" : "Google Account"
            } and automatically renew.
            To cancel this type of subscription you need to visit ${
              Capacitor.getPlatform() === "ios" ? "your account on iTunes or the App Store." : "Google Play."
            }`}
          </li>
          <li>If you already have a web subscription you can use the account details here.</li>
          <li>{`Payment will be charged to your ${
            Capacitor.getPlatform() === "ios" ? "iTunes Account" : "Google Account"
          } at confirmation of purchase.`}</li>
          <li>
            Subscription automatically renews unless auto-renew is turned off at least 24-hours before the end of the
            current period.
          </li>
          <li>
            Account will be charged for renewal within 24-hours prior to the end of the current period, and will
            identify the cost of the renewal.
          </li>
          <li>
            {`Subscriptions may be managed and auto-renewal may be turned off by going to the ${
              Capacitor.getPlatform() === "ios" ? "Account Settings in iTunes/App Store" : "Google Play Store"
            } after purchase.`}
          </li>
          <li>
            Please read the{" "}
            <a
              style={{ textDecoration: "underline", color: "#00535d" }}
              href="https://www.eostraining.com/privacy/"
              target={"_blank"}
            >
              Privacy Policy
            </a>{" "}
            and{" "}
            <a
              style={{ textDecoration: "underline", color: "#00535d" }}
              href="https://www.eostraining.com/terms/"
              target={"_blank"}
            >
              Terms of Use.
            </a>
          </li>
          <li>
            Any unused portion of a free trial period, if offered, will be forfeited when the user purchases a
            subscription to that publication, where applicable.
          </li>
        </ul>
      </div>
    </>
  );
};

export default SubscriptionsList;
