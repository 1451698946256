import { IonBackButton } from "@ionic/react";
import React from "react";
import { getPrevRoute } from "utils/Utils";
import "./CustomBackButton.css";

// for this to work, each new route must be contained with a forward slash (e.g. home/new-route-content = GOOD, home/new-route/content = BAD)
const CustomBackButton: React.FC<{ url: string }> = ({ url }) => {
  return <IonBackButton defaultHref={getPrevRoute(url)} />;
};

export default CustomBackButton;
