import {
  Book,
  Bookmark,
  Bulletin,
  LoginResponse,
  Module,
  Progress,
  QuizAttempt,
  RegisterResponse,
  Subscription,
  User,
} from "domain/Interfaces";
import {
  EOS_SERVER_BASE_URL,
  EOS_SERVER_BULLETINS,
  EOS_SERVER_LOGIN,
  EOS_SERVER_GET_QUIZ_ATTEMPTS,
  EOS_SERVER_ADD_QUIZ_ATTEMPT,
  EOS_SERVER_GET_LATEST_BOOK_CONTENT,
  EOS_SERVER_GET_BOOKMARKS,
  EOS_SERVER_ADD_BOOKMARK,
  EOS_SERVER_DEL_BOOKMARK,
  EOS_SERVER_GET_PROGRESS,
  EOS_SERVER_UPDATE_PROGRESS,
  EOS_SERVER_GENERATE_CERTIFICATE,
  EOS_SERVER_REGISTER,
  EOS_SERVER_REQUEST_ACCOUNT_DELETION,
  EOS_SERVER_GET_USER_DETAILS,
  EOS_SERVER_CREATE_SUBSCRIBER,
} from "utils/Constants";
import rhBook from "data/bookContentRH.json";
import ptBook from "data/bookContentPT.json";
import { getBookName, isSubValid } from "utils/Utils";
import moment from "moment";
import { Capacitor } from "@capacitor/core";

export namespace ServerService {
  export const login = async (email: string, password: string): Promise<LoginResponse> => {
    const loginResponse: LoginResponse = {
      success: false,
      user: null,
      message: "",
    };

    try {
      let response = await fetch(EOS_SERVER_BASE_URL + EOS_SERVER_LOGIN, {
        method: "POST",
        body: JSON.stringify({
          email: email,
          password: password,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      const responseJson = await response.json();
      if (responseJson.success) {
        loginResponse.success = true;
        loginResponse.user = {
          name: responseJson.individual_name__c,
          email: email,
          jwt: responseJson.jwt,
          code: responseJson.user__c,
          ptSub: null,
          rhSub: null,
        };
        loginResponse.message = "";
      } else {
        loginResponse.message = "Failed to login. Please check your email or password.";
      }
    } catch (error) {
      loginResponse.message = `Failed to login due to exception: ${error}`;
    }

    return loginResponse;
  };

  // retrieves subs from Salesforce. NOTE THAT SOME SUBS RETURNED COULD BE INVALID (EXPIRED)
  export const getSubsForUser = async (user: User): Promise<{ success: boolean; data: Subscription[] }> => {
    let subs: Subscription[] = [];
    let success = true;

    try {
      let response = await fetch(EOS_SERVER_BASE_URL + EOS_SERVER_GET_USER_DETAILS, {
        method: "POST",
        body: JSON.stringify({
          token: user.jwt,
          user__c: user.code,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      const responseJson = await response.json();
      console.log("getSubsForUser JSON reponse: ", responseJson);

      if (responseJson.success) {
        // parses subs from salesforce, and returns a types them correctly
        const tempArr: any[] = [];
        responseJson.sub.forEach((s: any) => {
          if (s.course_role__c === null) return; // subscriptions in SF must have passenger or road haulage set
          if (s.course_role__c.includes(";")) {
            // includes both, so add separately
            const ptItem = { ...s, course_role__c: "Passenger" };
            const rhItem = { ...s, course_role__c: "Road Haulage" };
            tempArr.push(ptItem);
            tempArr.push(rhItem);
          } else {
            tempArr.push(s);
          }
        });
        tempArr.forEach((s: any) => {
          subs.push({
            id: s.id,
            endDate: s.end_date__c === null ? moment().add(10, "years").toISOString() : s.end_date__c, // add 10 years for a sub without an end date (likely an eos-created sub). These are added manually to SF and are updated manually with an end date by EOS when expired
            type: s.course_role__c.includes("Passenger") ? "pt" : "rh",
            isEOScreated: s.subscription_id__c === "1405", // 1405 is the id for subsriptions bought through EOS.
          });
        });
      }
    } catch (error) {
      console.log("Failed to get user subs");
      success = false;
    }

    console.log("Subs obtained from server resposne: ", subs);

    return { success: success, data: subs };
  };

  export const register = async (name: string, email: string, password: string): Promise<RegisterResponse> => {
    let messageResponse = "Failed to register";

    try {
      let response = await fetch(EOS_SERVER_BASE_URL + EOS_SERVER_REGISTER, {
        method: "POST",
        body: JSON.stringify({
          name: name,
          email: email,
          password: password,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      const responseJson = await response.json();

      if (responseJson.success) {
        return {
          success: true,
          errorMessage: "",
        };
      } else {
        messageResponse = responseJson.body.ErrorMessage;
      }
    } catch (error) {
      console.log("Failed to register");
    }

    return {
      success: false,
      errorMessage: messageResponse,
    };
  };

  export const getBulletins = async (user: User | null): Promise<Bulletin[]> => {
    if (user == null) return [];

    const bulletins: Bulletin[] = [];

    try {
      const response = await fetch(EOS_SERVER_BASE_URL + EOS_SERVER_BULLETINS, {
        method: "POST",
        body: JSON.stringify({
          token: user.jwt,
          user__c: user.code,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      const responseJson = await response.json();

      if (responseJson.success) {
        responseJson.body.forEach((item: any) => {
          bulletins.push({
            id: item.id,
            title: item.title,
            html: item.html,
            date: item.date,
            subtitle: item.subtitle,
            live: item.live,
            name: item.name,
            lastModified: item["Last Modified"],
          });
        });
      }
    } catch (error) {
      console.log("Failed to get bulletins");
    }

    return bulletins;
  };

  export const fetchQuizAttempts = async (user: User): Promise<{ success: boolean; data: QuizAttempt[] }> => {
    const quizAttempts: QuizAttempt[] = [];

    try {
      const response = await fetch(EOS_SERVER_BASE_URL + EOS_SERVER_GET_QUIZ_ATTEMPTS, {
        method: "POST",
        body: JSON.stringify({
          token: user.jwt,
          user__c: user.code,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      const responseJson = await response.json();
      if (responseJson.status) {
        responseJson.body.forEach((item: QuizAttempt) => {
          quizAttempts.push(item);
        });
      }
    } catch (error) {
      console.log("Failed to get quiz attempts");
      return { success: false, data: [] };
    }

    return { success: true, data: quizAttempts };
  };

  export const syncLocalAttemptsWithRemoteDB = async (attempts: QuizAttempt[], user: User): Promise<QuizAttempt[]> => {
    const changedQuizAttempts: QuizAttempt[] = [];

    for (const a of attempts) {
      if (a.id === "" && a.isFinished) {
        const res = await ServerService.addQuizResult(user, a);
        if (res === null) {
          changedQuizAttempts.push(a);
        } else {
          changedQuizAttempts.push(res);
        }
      } else {
        // leave as is
        changedQuizAttempts.push(a);
      }
    }

    return changedQuizAttempts;
  };

  export const requestAccountDeletion = async (user: User) => {
    try {
      const response = await fetch(EOS_SERVER_BASE_URL + EOS_SERVER_REQUEST_ACCOUNT_DELETION, {
        method: "POST",
        body: JSON.stringify({
          token: user.jwt,
          user__c: user.code,
          email: user.email,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      const responseJson = await response.json();
      if (responseJson.success) return true;
    } catch (error) {
      console.log("Failed to request account deletion ", error);
    }

    return false;
  };

  export const generateCertificate = async (
    user: User,
    module: Module,
    book: Book,
    attempt: QuizAttempt,
    imageBase64String: string
  ): Promise<Blob | null> => {
    try {
      const response = await fetch(EOS_SERVER_BASE_URL + EOS_SERVER_GENERATE_CERTIFICATE, {
        method: "POST",
        body: JSON.stringify({
          token: user.jwt,
          user__c: user.code,
          moduleNumber: module.number,
          moduleName: module.name,
          userName: user.name,
          bookName: getBookName(book),
          score: attempt.score,
          maxScore: attempt.maxScore,
          imageBase64String: imageBase64String,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        return await response.blob();
      }
      return null;
    } catch (error) {
      console.log("Failed to generate certificate ", error);
      return null;
    }
  };

  // gets latest book content and quiz questions. a cron job is done every hour on the server to pull all data from airtable and store it in JSON files.
  // this function retrieves the data kept in those files.
  export const fetchLatestBookData = async (user: User | null): Promise<Book[]> => {
    if (user == null) return [];

    let books: Book[] = [];

    try {
      const response = await fetch(EOS_SERVER_BASE_URL + EOS_SERVER_GET_LATEST_BOOK_CONTENT, {
        method: "POST",
        body: JSON.stringify({
          token: user.jwt,
          user__c: user.code,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      const responseJson = await response.json();
      if (responseJson.success) {
        books = responseJson.data;
      } else {
        books = [rhBook, ptBook];
      }
    } catch (error) {
      console.log("Failed to retrieve book content from server. Using local content instead");
      books = [rhBook, ptBook];
    }

    return books;
  };

  export const createSub = async (
    user: User,
    productId: string,
    transactionId: string,
    expiryDate: string,
    startDate: string
  ): Promise<void> => {
    try {
      await fetch(EOS_SERVER_BASE_URL + EOS_SERVER_CREATE_SUBSCRIBER, {
        method: "POST",
        body: JSON.stringify({
          token: user.jwt,
          user__c: user.code,
          productIdentifier: productId,
          transactionIdentifier: transactionId,
          expiryDate: expiryDate,
          originalStartDate: startDate,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
    } catch (error) {
      console.log("Failed to update user's subscription");
    }
  };

  // returns newly created quiz result, null otherwise. note that unfinished quiz results are not posted to remote db
  export const addQuizResult = async (user: User, quizAttempt: QuizAttempt): Promise<QuizAttempt | null> => {
    let newQuizResult: QuizAttempt | null = null;

    try {
      const response = await fetch(EOS_SERVER_BASE_URL + EOS_SERVER_ADD_QUIZ_ATTEMPT, {
        method: "POST",
        body: JSON.stringify({
          token: user.jwt,
          user__c: user.code,
          quizResult: JSON.stringify(quizAttempt),
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      const responseJson = await response.json();
      if (responseJson.status) {
        newQuizResult = responseJson.quizResult;
      }
    } catch (error) {
      console.log("Failed to add quiz attempt to remote DB");
    }

    return newQuizResult;
  };

  export const fetchProgress = async (user: User): Promise<{ success: boolean; data: Progress }> => {
    let prog: Progress = { rhFinishedChapters: [], ptFinishedChapters: [] };

    try {
      const response = await fetch(EOS_SERVER_BASE_URL + EOS_SERVER_GET_PROGRESS, {
        method: "POST",
        body: JSON.stringify({
          token: user.jwt,
          user__c: user.code,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      const responseJson = await response.json();
      if (responseJson.success) {
        const data = JSON.parse(responseJson.data[0].progress);
        prog = {
          // id: responseJson.id,
          ptFinishedChapters: data.ptFinishedChapters,
          rhFinishedChapters: data.rhFinishedChapters,
        };
      }
    } catch (error) {
      console.log("Failed to get quiz user's progress");
      return { success: false, data: { rhFinishedChapters: [], ptFinishedChapters: [] } };
    }

    return { success: true, data: prog };
  };

  export const updateProgress = async (user: User | null, prog: Progress): Promise<Progress | null> => {
    if (user == null) return null;

    let newProg: Progress | null = null;

    try {
      const response = await fetch(EOS_SERVER_BASE_URL + EOS_SERVER_UPDATE_PROGRESS, {
        method: "POST",
        body: JSON.stringify({
          token: user.jwt,
          user__c: user.code,
          prog: JSON.stringify(prog),
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      const responseJson = await response.json();

      if (responseJson.status) {
        newProg = responseJson.body;
      }
    } catch (error) {
      console.log("Failed to update progress in remote DB");
    }

    return newProg;
  };

  export const fetchBookmarks = async (user: User): Promise<{ success: boolean; data: Bookmark[] }> => {
    const bookmarks: Bookmark[] = [];

    try {
      const response = await fetch(EOS_SERVER_BASE_URL + EOS_SERVER_GET_BOOKMARKS, {
        method: "POST",
        body: JSON.stringify({
          token: user.jwt,
          user__c: user.code,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      const responseJson = await response.json();
      if (responseJson.status) {
        responseJson.bookmarks.forEach((item: Bookmark) => {
          bookmarks.push(item);
        });
      }
    } catch (error) {
      console.log("Failed to get bookmarks");
      return { success: false, data: [] };
    }

    return { success: true, data: bookmarks };
  };

  // makes sure that the local copy of bookmarks stored in Bookmarks-<userId>.json is replicated on the database
  export const syncLocalBookmarksWithRemoteDB = async (localBookmarks: Bookmark[], user: User): Promise<Bookmark[]> => {
    const changedBookmarks: Bookmark[] = [];

    for (const b of localBookmarks) {
      if (b.id === "") {
        const res = await addBookmark(user, b);
        if (res === null) {
          changedBookmarks.push(b);
        } else {
          changedBookmarks.push(res);
        }
      } else {
        changedBookmarks.push(b);
      }
    }

    return changedBookmarks;
  };

  // returns newly created bookmark, null otherwise
  export const addBookmark = async (user: User, bookmark: Bookmark): Promise<Bookmark | null> => {
    let newBookmark: Bookmark | null = null;

    try {
      const response = await fetch(EOS_SERVER_BASE_URL + EOS_SERVER_ADD_BOOKMARK, {
        method: "POST",
        body: JSON.stringify({
          token: user.jwt,
          user__c: user.code,
          ref: JSON.stringify(bookmark),
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });

      const responseJson = await response.json();
      console.log("responseJson", responseJson);

      if (responseJson.status) {
        newBookmark = responseJson.bookmark;
      }
    } catch (error) {
      console.log("Failed to add bookmark to remote DB");
    }

    return newBookmark;
  };

  export const delBookmark = async (user: User, bookmark: Bookmark): Promise<void> => {
    try {
      const response = await fetch(EOS_SERVER_BASE_URL + EOS_SERVER_DEL_BOOKMARK, {
        method: "POST",
        body: JSON.stringify({
          token: user.jwt,
          user__c: user.code,
          ref: JSON.stringify(bookmark),
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
    } catch (error) {
      console.log("Failed to delete bookmark on remote DB");
    }
  };
}
