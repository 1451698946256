import { IonIcon, IonItem, IonLabel } from "@ionic/react";
import { Chapter, Module } from "domain/Interfaces";
import "./ContinueReadingBubble.css";

const ContinueReadingBubble: React.FC<{
  handleClick: () => void;
  moduleNumber: number;
  moduleName: string;
  chapter: Chapter;
}> = ({ handleClick, moduleNumber, moduleName, chapter }) => {
  return (
    <div id="continueReadingBubble" onClick={handleClick}>
      <h3>Module {moduleNumber}</h3>
      <h3>{moduleName}</h3>
      <div id="chapterLineContainer">
        <h3 id="chapterName">
          {chapter.number === 0 ? "" : `${chapter.number}. `} {chapter.name}
        </h3>
        <IonIcon id="chapterArrow" icon={"./assets/svgs/Button_Disclosure_Right_Arrow.svg"} />
      </div>
    </div>
  );
};

export default ContinueReadingBubble;
