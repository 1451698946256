import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonList,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import ChapterListItem from "components/ChapterListItem/ChapterListItem";
import CustomBackButton from "components/CustomBackButton/CustomBackButton";
import MarkAsFinishedModal from "components/MarkAsFinishedModal/MarkAsFinishedModal";
import { AppContext } from "domain/AppContextProvider";
import { Progress } from "domain/Interfaces";
import { setProgress } from "features/progress/progressSlice";
import { useAppDispatch, useAppSelector } from "hooks/customReduxHooks";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { ServerService } from "services/ServerService";
import {
  checkIfChapterIsFinished,
  checkIfModuleIsFinished,
  getBookClassBg,
  getCurrentBookFromBookID,
} from "utils/Utils";
import "./ChapterList.css";

const ChapterList: React.FC<{}> = () => {
  const location = useLocation();
  const history = useHistory();
  const params = useParams<{ moduleNumber: string }>();

  const dispatch = useAppDispatch();

  const { progress } = useAppSelector(state => state);
  const { appContext } = useContext(AppContext);

  const books = appContext.books;
  const bookID = appContext.bookID;
  const currentBook = getCurrentBookFromBookID(books, bookID);
  const currentModule = currentBook.modules.find(mod => mod.number === parseInt(params.moduleNumber))!;
  const isModuleFinished = checkIfModuleIsFinished(bookID, progress.progress, currentModule);

  const [modalVisible, setModalVisible] = useState(false);

  const handleMarkAsFinishedClick = () => {
    let newProgress: Progress = {
      rhFinishedChapters: [...progress.progress.rhFinishedChapters],
      ptFinishedChapters: [...progress.progress.ptFinishedChapters],
    };
    const key = bookID === 1 ? "rhFinishedChapters" : "ptFinishedChapters";

    const newArr: string[] = [];
    currentModule.chapters.forEach(c => {
      if (!checkIfChapterIsFinished(bookID, progress.progress, currentModule.number, c.number)) {
        newArr.push(`${currentModule.number}-${c.number}`);
      }
    });
    newProgress[key] = [...newProgress[key], ...newArr];
    dispatch(setProgress({ progress: newProgress, user: appContext.user! }));

    ServerService.updateProgress(appContext.user, newProgress);

    setModalVisible(false);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className={getBookClassBg(bookID)}>
          <IonTitle>{module && `Module ${params.moduleNumber}`}</IonTitle>
          <IonButtons slot="start">
            <CustomBackButton url={location.pathname} />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent scrollY={false}>
        {currentModule ? (
          <>
            <div className="contentWrapper">
              <div id="chapterListContainer">
                <h2>Module {currentModule.number}</h2>
                <h1>{currentModule.name}</h1>
                <IonList>
                  {currentModule.chapters
                    .sort((a, b) => (a.number < b.number ? -1 : 1))
                    .map((chapter, index) => {
                      return (
                        <ChapterListItem
                          key={index}
                          chapter={chapter}
                          handleClick={() =>
                            history.push(`${location.pathname}/chapter-content`, { chapterNumber: chapter.number })
                          }
                          moduleNumber={currentModule.number}
                        />
                      );
                    })}
                </IonList>
                <div id="moduleButtonsContainer">
                  <IonLabel>Finished reading this module?</IonLabel>
                  <IonButton
                    disabled={isModuleFinished}
                    style={{
                      "--background": isModuleFinished ? "var(--status-finished)" : "var(--reg-button)",
                    }}
                    onClick={() => setModalVisible(true)}
                  >
                    {isModuleFinished && <IonIcon icon="./assets/svgs/Button_Answer_Tick.svg" />}
                    {isModuleFinished ? "Finished" : "Mark as finished"}
                  </IonButton>
                  {currentModule.quizQuestions.length > 0 && (
                    <>
                      <IonLabel>Ready to test your knowledge?</IonLabel>
                      <IonButton onClick={() => history.push(`/quizzes/overview-module-${currentModule.number}`)}>
                        Take quiz
                      </IonButton>
                    </>
                  )}
                  {currentBook.modules.length > currentModule.number && (
                    <IonButton
                      style={{ marginTop: "45px" }}
                      onClick={() => {
                        history.push(`/modules/module-${currentModule.number + 1}`);
                      }}
                    >
                      Next Module
                    </IonButton>
                  )}
                </div>
              </div>
            </div>
            <MarkAsFinishedModal
              isOpen={modalVisible}
              onCancel={() => setModalVisible(false)}
              onMarkAsFinished={handleMarkAsFinishedClick}
            />
          </>
        ) : (
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontFamily: "orbi-med",
              fontSize: "22px",
              color: "#00535d",
            }}
          >
            {`Module ${params.moduleNumber} does not exist`}
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default ChapterList;
