import { IonContent, IonPage } from "@ionic/react";
import "./PageNotFound.css";

const PageNotFound = () => {
  return (
    <IonPage>
      <IonContent scrollY={false}>
        <div id="pageNotFoundContent" className="contentWrapper">
          Page Not Found
        </div>
      </IonContent>
    </IonPage>
  );
};

export default PageNotFound;
