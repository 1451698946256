import { createContext, useEffect, useState } from "react";
import { AppContextType, AppContextData, ThemeSelectionOption, BookID } from "./Interfaces";
import { LocalStorageHelper } from "./LocalStorageHelper";
import { LAST_READ_CHAPTERS_DEFAULT, THEME_SELECTION_DEFAULT } from "utils/Constants";
import { App } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";
import { Device } from "@capacitor/device";
import { PushNotifications, PushNotificationSchema, ActionPerformed } from "@capacitor/push-notifications";
import useLoadAppData from "hooks/useLoadAppData";
import LoadingPage from "pages/LoadingPage/LoadingPage";
import { validateSubscriptionForUser } from "utils/Utils";

export const AppContext = createContext({} as AppContextType);

const AppContextProvider: React.FC = ({ children }) => {
  const loadAppData = useLoadAppData();

  const [appContext, setAppContext] = useState<AppContextData>({
    isInitialised: false,
    user: null,
    books: [],
    bookID: 0,
    theme: THEME_SELECTION_DEFAULT,
    appInfo: null,
    deviceInfo: null,
    bulletins: [],
    lastReadChapters: LAST_READ_CHAPTERS_DEFAULT,
    viewingChapterContent: false,
  });

  useEffect(() => {
    if (Capacitor.getPlatform() === "web") return;

    PushNotifications.addListener("pushNotificationReceived", (notification: PushNotificationSchema) => {
      console.log("notification is received and the app is open");
    });
    PushNotifications.addListener("pushNotificationActionPerformed", (notification: ActionPerformed) => {
      console.log("notification is tapped");
    });
  }, []);

  useEffect(() => {
    if (!appContext.isInitialised) init();
  }, []);

  useEffect(() => {
    if (appContext.user) {
      console.log("Saving user to local storage...");
      LocalStorageHelper.setUser(appContext.user);
    }
  }, [appContext.user]);

  useEffect(() => {
    if (appContext.bookID !== BookID.Default) {
      console.log("Saving book selection to local storage...", appContext.bookID);
      LocalStorageHelper.setBookSelection(appContext.bookID);
    }
  }, [appContext.bookID]);

  const init = async () => {
    const appInfo = await getAppInfo();
    const deviceInfo = await getDeviceInfo();
    const lastReadChapters = await LocalStorageHelper.getLastReadChapters();
    const theme = await LocalStorageHelper.getTheme();
    setTheme(theme);

    // if there is a user, they've already logged in
    let user = await LocalStorageHelper.getUser();
    if (user) {
      user = await validateSubscriptionForUser(user);
    }

    const appData = await loadAppData(user);

    const bookID = await LocalStorageHelper.getBookSelection(user);

    setAppContext(prev => ({
      ...prev,
      isInitialised: true,
      user: user,
      books: appData.bookData,
      bookID: bookID,
      theme: theme,
      appInfo: appInfo,
      deviceInfo: deviceInfo,
      bulletins: appData.bulletins,
      lastReadChapters: lastReadChapters,
    }));
  };

  const getAppInfo = async () => {
    if (Capacitor.getPlatform() === "web") return null;

    const info = await App.getInfo();
    return {
      build: info.build,
      id: info.id,
      name: info.name,
      version: info.version,
    };
  };

  const getDeviceInfo = async () => {
    const info = await Device.getInfo();
    return {
      manufacturer: info.manufacturer,
      model: info.model,
      name: info.name,
      operatingSystem: info.operatingSystem,
      osVersion: info.osVersion,
      platform: info.platform,
    };
  };

  const setTheme = (theme: ThemeSelectionOption) => {
    switch (theme) {
      case ThemeSelectionOption.Dark:
        document.body.classList.add("dark");
        break;
      case ThemeSelectionOption.Light:
        document.body.classList.remove("dark");
        break;
      case ThemeSelectionOption.Auto:
        const prefersDark = window.matchMedia("(prefers-color-scheme: dark)");
        prefersDark.matches ? document.body.classList.add("dark") : document.body.classList.remove("dark");
        break;
    }
  };

  const value = {
    appContext,
    setAppContext,
  };

  if (!appContext.isInitialised) {
    return <LoadingPage text={"Loading..."} />;
  }

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export default AppContextProvider;
