import React, { useContext, useEffect, useRef, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonList,
  IonMenu,
  IonMenuButton,
  IonPage,
  IonSearchbar,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
  useIonLoading,
} from "@ionic/react";
import { Subscriptions } from "capacitor-subscriptions";
import { Capacitor } from "@capacitor/core";
import { AppContext } from "domain/AppContextProvider";
import { BookID, Chapter, Module } from "domain/Interfaces";
import { LocalStorageHelper } from "domain/LocalStorageHelper";
import { useAppDispatch, useAppSelector } from "hooks/customReduxHooks";
import { ServerService } from "services/ServerService";
import { setBookmarks } from "features/bookmarks/bookmarksSlice";
import { setProgress } from "features/progress/progressSlice";
import { setQuizAttempts } from "features/quizAttempts/quizAttemptsSlice";
import { useHistory } from "react-router";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  formatDate,
  getBookClassBg,
  getCurrentBookFromBookID,
  getLatestBulletin,
  getLatestQuizAttemptForModule,
  getQuizAttemptsForModule,
  trimHtmlTags,
  isSubValid,
  displaySubStatus,
} from "utils/Utils";
import SwiperCore from "swiper";
import LatestBulletinBubble from "components/LatestBulletinBubble/LatestBulletinBubble";
import QuizBubble from "components/QuizBubble/QuizBubble";
import BookmarksList from "components/BookmarksList/BookmarksList";
import BookmarksModal from "components/BookmarksModal/BookmarksModal";
import ContinueReadingBubble from "components/ContinueReadingBubble/ContinueReadingBubble";
import MenuItem from "components/MenuItem/MenuItem";
import moment from "moment";
import InAppPurchasesModal from "components/InAppPurchasesModal/InAppPurchasesModal";
import SubscriptionsList from "components/SubscriptionsList/SubscriptionsList";
import ContactSupport from "components/ContactSupport/ContactSupport";
import RequestDeleteButton from "components/RequestDeleteButton/RequestDeleteButton";
import "./DashboardTab.css";

// Android/iOS - this dashboard will be covered by the IAP modal (where purchases can be made) if the user does not have a valid sub.
// web - a useEffect on this dashboard will immediately logout the user if they don't have a valid sub.
const DashboardTab: React.FC = () => {
  const history = useHistory();

  const dispatch = useAppDispatch();

  const { appContext, setAppContext } = useContext(AppContext);
  const { allQuizAttempts } = useAppSelector(state => state);

  const user = appContext.user!;
  const books = appContext.books;
  const bookID = appContext.bookID;
  const currentBook = getCurrentBookFromBookID(books, bookID);
  const bookKey = bookID === 1 ? "rh" : "pt";
  const lastReadChaptersObj = appContext.lastReadChapters[bookKey];
  const lastReadMod = currentBook.modules.find(m => m.number === lastReadChaptersObj.module);
  const lastReadChap = lastReadMod?.chapters.find(c => c.number === lastReadChaptersObj.chapter);
  const latestBulletin = getLatestBulletin(appContext.bulletins);
  const inProgressAttempts = checkForInProgressAttempts();

  const menuRef = useRef<HTMLIonMenuElement>(null);
  const searchBarRef = useRef<HTMLIonSearchbarElement>(null);

  const [presentSyncingOverlay, dismissSyncingOverlay] = useIonLoading();
  const [presentLoggingOutOverlay] = useIonLoading();
  const [presentChangingBookOverlay] = useIonLoading();

  const [showIapModal, setShowIapModal] = useState(false);

  const [slideTwo, setSlideTwo] = useState("");
  const [menuSwiperRef, setMenuSwiperRef] = useState<SwiperCore | null>(null);
  const [showBookmarksModal, setShowBookmarksModal] = useState(false);
  const [showSearchView, setShowSearchView] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filteredSearchItems, setFilteredSearchItems] = useState<
    {
      module: Module;
      chapter: Chapter;
      text: string;
      indexOfSearchRes: number;
    }[]
  >([]);

  function checkForInProgressAttempts() {
    let res = false;
    currentBook.modules.forEach(mod => {
      const filteredQuizAttempts = getQuizAttemptsForModule(bookID, mod.number, allQuizAttempts.attempts);
      const latestQuizAttempt = getLatestQuizAttemptForModule(filteredQuizAttempts);
      if (latestQuizAttempt != null && !latestQuizAttempt.isFinished) {
        res = true;
      }
    });

    return res;
  }

  useEffect(() => {
    if (Capacitor.getPlatform() === "web" && !user.ptSub && !user.rhSub) {
      handleLogOut();
    } else {
      setShowIapModal(true);
    }
  }, []);

  useEffect(() => {
    if (showSearchView) {
      setTimeout(() => {
        searchBarRef.current?.setFocus();
      }, 100);
    }
  }, [showSearchView]);

  useEffect(() => {
    if (isSubValid(user.ptSub) || isSubValid(user.rhSub)) {
      setShowIapModal(false);
    }
  });

  const handleSearch = (text: string) => {
    setSearchText(text);

    const trimmedSearchText = text.toLowerCase().trim();

    if (trimmedSearchText.trim() === "") {
      setFilteredSearchItems([]);
    } else {
      const monolithicArray: { module: Module; chapter: Chapter; text: string; indexOfSearchRes: number }[] = [];

      currentBook.modules.forEach(mod => {
        mod.chapters.forEach(c => {
          let allTextForChapter: string = "";
          c.sections.forEach(s => {
            allTextForChapter += s.html;
          });

          const text = trimHtmlTags(allTextForChapter);

          monolithicArray.push({
            module: mod,
            chapter: c,
            text: text,
            indexOfSearchRes: text.toLowerCase().indexOf(trimmedSearchText),
          });
        });
      });

      const filteredArray = monolithicArray.filter(m => m.indexOfSearchRes !== -1);
      setFilteredSearchItems(filteredArray);
    }
  };

  const handleContinueReading = () => {
    history.push(`/modules/module-${lastReadMod?.number}/chapter-content`, {
      chapterNumber: lastReadChap?.number,
    });
  };

  const handleMenuItemClick = (slide: string) => {
    setSlideTwo(slide);
    menuSwiperRef?.slideNext();
  };

  const handleSync = async () => {
    presentSyncingOverlay({ message: "Syncing..." });

    // sync bookmarks - only overwrite if successful
    const bms = await ServerService.fetchBookmarks(user);
    if (bms.success) {
      await LocalStorageHelper.saveBookmarks(bms.data, user.code);
      dispatch(setBookmarks({ bms: bms.data, userId: user.code }));
    }

    // sync quiz attempts - only overwrite if successful
    const quizAttempts = await ServerService.fetchQuizAttempts(user);
    if (quizAttempts.success) {
      await LocalStorageHelper.saveQuizAttempts(quizAttempts.data, user.code);
      dispatch(setQuizAttempts({ attempts: quizAttempts.data, userId: user.code }));
    }

    // sync progress - only overwrite if successful
    const progress = await ServerService.fetchProgress(user);
    if (progress.success) {
      await LocalStorageHelper.saveProgress(progress.data, user.code);
      dispatch(setProgress({ progress: progress.data, user: user }));
    }

    dismissSyncingOverlay();
  };

  const handleLogOut = async () => {
    presentLoggingOutOverlay({ message: "Logging out..." });
    await LocalStorageHelper.removeUser();
    await LocalStorageHelper.removeBookSelection();
    setTimeout(() => {
      window.location.reload();
    }, 1500);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar className={getBookClassBg(bookID)}>
          <IonButtons slot="start">
            <IonMenuButton id="menuButton" mode="ios" menu="menu" />
          </IonButtons>
          <IonTitle>Dashboard</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent id="main" scrollY={false}>
        <div className="contentWrapper">
          {/* <div style={{ color: "red" }}>
            <pre>{JSON.stringify(appContext.bookID, null, 4)}</pre>
            <pre>{JSON.stringify(appContext.user, null, 4)}</pre>
          </div> */}
          <div id="dashboardContentContainer" style={{ height: showSearchView ? "100%" : "initial" }}>
            {showSearchView ? (
              <div id="searchViewContainer">
                <div className="searchInputContainer">
                  <IonSearchbar
                    style={{ marginBottom: "12px" }}
                    showCancelButton="always"
                    onIonCancel={() => setShowSearchView(false)}
                    onIonChange={e => handleSearch(e.detail.value!)}
                    ref={searchBarRef}
                    placeholder="Search Modules"
                    mode="ios"
                    id="searchInputItem"
                    searchIcon="./assets/svgs/Button_Search_Small.svg"
                  />
                </div>
                <div
                  style={{
                    color: "var(--module-list-item-number)",
                    fontSize: "14px",
                    fontFamily: "noto-bold",
                    textAlign: "end",
                  }}
                >
                  {`${filteredSearchItems.length} results`}
                </div>
                {filteredSearchItems.map((item, i) => {
                  const str =
                    "<p>" +
                    item.text.slice(item.indexOfSearchRes - 100, item.indexOfSearchRes) +
                    "<span class='searchTextHighlight'>" +
                    item.text.slice(item.indexOfSearchRes, item.indexOfSearchRes + searchText.length) +
                    "</span>" +
                    item.text.slice(
                      item.indexOfSearchRes + searchText.length,
                      item.indexOfSearchRes + searchText.length + 200
                    ) +
                    "</p>";
                  return (
                    <div
                      key={i}
                      onClick={() =>
                        history.push(`/modules/module-${item.module.number}/chapter-content`, {
                          chapterNumber: item.chapter.number,
                        })
                      }
                      className="searchResultItem"
                    >
                      <div>{`Module ${item.module.number} | ${item.module.name}`}</div>
                      <div>{`${item.chapter.name} (Chapter ${item.chapter.number})`}</div>
                      <div dangerouslySetInnerHTML={{ __html: str }} />
                      <div className="basicDivider" />
                    </div>
                  );
                })}
              </div>
            ) : (
              <>
                <div id="bookSelectionContainer">
                  <IonSelect
                    slot="start"
                    mode="md"
                    disabled={!isSubValid(user.ptSub) || !isSubValid(user.rhSub)}
                    onIonChange={e => {
                      // display an "artificial" loading overlay to make the reload flicker/transition more understandable for the user
                      presentChangingBookOverlay({ message: "Changing Book" });
                      setAppContext(prev => ({ ...prev, bookID: e.detail.value }));
                      setTimeout(() => {
                        // reload the browser to avoid an exception caused when 2 different books have a different number of chapters for the same module number. example of the exception:
                        // user selects the Road Haulage book from the /dashboard tab
                        // user navigates to the /modules tab
                        // user selects module 12
                        // user selects chapter 7
                        // user navigates back to the /dashboard tab
                        // user selects the Passenger Transport book
                        // exception thrown!! Every tab is rendered at the same time (even if it isn't the active tab) which means the app is attempting to render the ChapterContent and
                        // render module 12, chapter 7 for Passenger Transport but this chapter doesn't exist! There are only 6 chapters for module 12 in Passenger Transport.
                        // Ideally, the app should just reset the navigation of the modules tab when the book is changed but this isn't possible (https://github.com/ionic-team/ionic-framework/issues/17761).
                        // Redirecting routes if the chapter doesn't exist in the module didn't work either.
                        window.location.reload();
                      }, 1000);
                    }}
                    // defaultValue={bookSelection.value}
                    interface="popover"
                    value={bookID}
                    style={{
                      backgroundColor: bookID === BookID.RoadHaulage ? "var(--book-bg-rh)" : "var(--book-bg-pt)",
                    }}
                  >
                    <IonSelectOption value={1}>{"Road Haulage"}</IonSelectOption>
                    <IonSelectOption value={2}>{"Passenger Transport"}</IonSelectOption>
                  </IonSelect>
                </div>
                <div id="bookSelectionDivider" />
                <div className="searchInputContainer">
                  <IonSearchbar
                    placeholder="Search Modules"
                    mode="ios"
                    id="searchInputItem"
                    searchIcon="./assets/svgs/Button_Search_Small.svg"
                    onIonFocus={() => setShowSearchView(true)}
                  />
                </div>
                <h1>Continue Reading</h1>
                {lastReadChap && (
                  <ContinueReadingBubble
                    handleClick={handleContinueReading}
                    moduleNumber={lastReadMod?.number!}
                    moduleName={lastReadMod?.name!}
                    chapter={lastReadChap}
                  />
                )}
                <div id="bookmarksHeadingContainer">
                  <h1>Bookmarks</h1>
                  <IonButton onClick={() => setShowBookmarksModal(true)}>See all</IonButton>
                </div>
                <BookmarksList showAll={false} />
                <h1>Quizzes In Progress</h1>
                {inProgressAttempts === false && (
                  <div className="emptyBookmarksContainer">
                    You haven’t got any quizzes in progress. The latest quiz you haven’t fully completed (for each
                    module) will appear here.
                  </div>
                )}
                {inProgressAttempts &&
                  currentBook.modules.map((mod, index) => {
                    const filteredQuizAttempts = getQuizAttemptsForModule(bookID, mod.number, allQuizAttempts.attempts);
                    const latestQuizAttempt = getLatestQuizAttemptForModule(filteredQuizAttempts);
                    if (latestQuizAttempt != null && !latestQuizAttempt.isFinished) {
                      if (moment(latestQuizAttempt.dateTimeStarted).isAfter(mod.quizUpdateTime)) {
                        return (
                          <QuizBubble
                            key={index}
                            module={mod}
                            handleClick={() => history.push(`/quizzes/overview-module-${mod.number}`)}
                          />
                        );
                      }
                    }
                  })}
                <h1>Latest Bulletin</h1>
                {latestBulletin !== null && (
                  <div style={{ marginBottom: "32px" }}>
                    {appContext.bulletins.length && (
                      <LatestBulletinBubble
                        handleClick={() => history.push(`/bulletins/bulletin-${latestBulletin.id}`)}
                        date={formatDate(latestBulletin.date)}
                        title={latestBulletin.title}
                      />
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <BookmarksModal isOpen={showBookmarksModal} handleClose={() => setShowBookmarksModal(false)} />
      </IonContent>
      <IonMenu ref={menuRef} type={"overlay"} contentId="main" side="start" menuId="menu" swipeGesture={false}>
        <IonHeader>
          <IonToolbar className={getBookClassBg(bookID)}>
            {slideTwo !== "" && (
              <IonButtons slot="start">
                <IonButton
                  style={{ "--color": "white" }}
                  onClick={() => {
                    setSlideTwo("");
                    menuSwiperRef?.slidePrev();
                  }}
                >
                  <img id="menuBack" slot="start" src={"./assets/svgs/Button_Disclosure_Left.svg"} />
                  Back
                </IonButton>
              </IonButtons>
            )}
            <IonTitle>{slideTwo}</IonTitle>
            <IonButtons slot="end">
              <IonButton
                style={{ "--color": "white", fontFamily: "noto-bold" }}
                onClick={() => menuRef.current?.close()}
              >
                Close
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent id="menuContent">
          <Swiper onInit={swiper => setMenuSwiperRef(swiper)} allowTouchMove={false} id="menuSwiper">
            <SwiperSlide
              className="menuItemSlide"
              style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}
            >
              <IonList>
                <MenuItem
                  text={"My account"}
                  subText={user.email}
                  handleClick={() => handleMenuItemClick("Account")}
                  imgSrc="./assets/images/Button_Menu_Account.png"
                />
                <MenuItem
                  text={"Settings"}
                  handleClick={() => handleMenuItemClick("Settings")}
                  imgSrc="./assets/images/Button_Menu_Settings.png"
                />
                <MenuItem
                  text={"Help & information"}
                  handleClick={() => handleMenuItemClick("Help")}
                  imgSrc="./assets/images/Button_Menu_Help.png"
                />
                <MenuItem
                  text={"Visit eostraining.com"}
                  imgSrc="./assets/images/Button_Logo_EOS.png"
                  link="https://www.eostraining.com/"
                />
              </IonList>
              {appContext.appInfo !== null && (
                <div style={{ marginBottom: "20px", textAlign: "center", fontSize: "13px", fontFamily: "noto-reg" }}>
                  <div>Build: {appContext.appInfo?.build}</div>
                  <div>Version: {appContext.appInfo?.version}</div>
                </div>
              )}
            </SwiperSlide>
            <SwiperSlide className="menuItemSlide">
              <div className="dashSubMenu">
                {slideTwo === "Account" ? (
                  <>
                    <div style={{ fontFamily: "orbi-bold", fontSize: "22px", color: "#00535d" }}>My Account</div>
                    <div style={{ fontFamily: "noto-reg", fontSize: "13px", marginTop: "16px", color: "#666666" }}>
                      Signed in as
                    </div>
                    <div
                      style={{
                        fontFamily: "noto-reg",
                        fontSize: "17px",
                        marginTop: "4px",
                        color: "var(--color-black-white-subtle)",
                      }}
                    >
                      {user.email}
                    </div>
                    <IonButton
                      fill="outline"
                      style={{ color: "red", "--border-color": "red", marginTop: "16px" }}
                      onClick={handleLogOut}
                    >
                      Log out
                    </IonButton>
                    <RequestDeleteButton addMargin={false} />
                    <div style={{ fontFamily: "noto-reg", fontSize: "13px", marginTop: "16px", color: "#666666" }}>
                      My subscriptions
                    </div>
                    <div
                      style={{
                        fontFamily: "noto-reg",
                        fontSize: "17px",
                        marginTop: "4px",
                        color: "var(--color-black-white-subtle)",
                      }}
                    >
                      <div style={{ fontWeight: "bold" }}>Passenger Transport:</div>
                      <div>{displaySubStatus(user.ptSub)}</div>
                      <div style={{ fontWeight: "bold", marginTop: "2px" }}>Road Haulage:</div>
                      <div>{displaySubStatus(user.rhSub)}</div>
                    </div>
                    {(Capacitor.getPlatform() === "ios" || Capacitor.getPlatform() === "android") && (
                      <IonButton
                        style={{ color: "#006b8c", "--border-color": "#006b8c", marginTop: "16px" }}
                        fill="outline"
                        onClick={() => Subscriptions.manageSubscriptions()}
                      >
                        Manage my subscriptions
                      </IonButton>
                    )}
                    {Capacitor.getPlatform() === "web" ? <ContactSupport isForModal={false} /> : <SubscriptionsList />}
                  </>
                ) : slideTwo === "Help" ? (
                  <>
                    <div>
                      <div className="dashHeaderText">What is EOS TM?</div>
                      <div className="dashRegText">
                        EOS Training is the market leader in the supply of training material for both freight and
                        passenger Certificates of Professional Competence (Manager CPC). The app is available to support
                        passenger transport and road haulage professionals with your learning.
                      </div>
                      <div className="dashHeaderText">How do I get access to EOS TM?</div>
                      <div className="dashRegText">
                        Access to the EOS TM app is available to learners who have a subscription with EOS. Visit
                        <a href="https://www.eostraining.com/" target={"_blank"}>
                          {" "}
                          www.eostraining.com
                        </a>{" "}
                        for more information.
                      </div>
                      <div className="dashHeaderText">How do I reset my password?</div>
                      <div className="dashRegText">
                        Visit{" "}
                        <a href="https://www.classprofessional.co.uk/app-reset" target={"_blank"}>
                          {" "}
                          www.classprofessional.co.uk/app-reset
                        </a>{" "}
                        to reset your password.
                      </div>
                      <div
                        style={{
                          marginTop: "22px",
                          fontFamily: "noto-bold",
                          fontSize: "14px",
                          color: "#6f757d",
                        }}
                      >
                        More information can be found{" "}
                        <a href="https://www.classprofessional.co.uk/faqs/#eos-tm " target={"_blank"}>
                          {" "}
                          here
                        </a>
                      </div>
                    </div>
                  </>
                ) : slideTwo === "Settings" ? (
                  <div>
                    <div className="dashHeaderText">Sync data across multiple platforms</div>
                    <div className="dashRegText">
                      If you use this application across multiple platforms (e.g. mobile app and web), you may notice
                      that some of your progress, bookmarks, and quiz attempts are not synced.
                      <br />
                      <br />
                      <b>Tap the button below to sync your data</b>
                    </div>
                    <IonButton
                      fill="outline"
                      style={{ color: "red", "--border-color": "red", marginTop: "32px" }}
                      onClick={handleSync}
                    >
                      Sync my data
                    </IonButton>
                  </div>
                ) : null}
              </div>
            </SwiperSlide>
          </Swiper>
        </IonContent>
      </IonMenu>
      <InAppPurchasesModal isOpen={showIapModal} handleClose={() => setShowIapModal(false)} />
    </IonPage>
  );
};

export default React.memo(DashboardTab);
