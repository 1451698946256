const LoginTermsAndConditions = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <div id="termsAndConditionsLink">
        Please read our{" "}
        <a
          style={{ textDecoration: "underline", color: "#00535d" }}
          href="https://www.eostraining.com/privacy/"
          target={"_blank"}
        >
          Privacy Policy
        </a>{" "}
        and{" "}
        <a
          style={{ textDecoration: "underline", color: "#00535d" }}
          href="https://www.eostraining.com/terms/"
          target={"_blank"}
        >
          Terms & Conditions
        </a>
      </div>
      <img id="eosLogo" width={"44px"} height={"44px"} src={"./assets/images/EOS_Logo.png"} />
    </div>
  );
};

export default LoginTermsAndConditions;
