import { IonButton, IonContent, IonLabel, IonModal, IonPage } from "@ionic/react";
import "./MarkAsFinishedModal.css";

const MarkAsFinishedModal: React.FC<{
  isOpen: boolean;
  onMarkAsFinished: () => void;
  onCancel: () => void;
}> = ({ isOpen, onMarkAsFinished, onCancel }) => {
  return (
    <IonModal isOpen={isOpen} class="markAsFinishedModal" backdropDismiss={false} mode={"ios"}>
      <div>
        <div id="innerContainer">
          <IonLabel>Mark Module as finished?</IonLabel>
          <p>
            Marking this Module as finished will mean all chapters in this Module will appear as finished. Are you sure
            you want to mark this Module as finished?
          </p>
        </div>
        <div id="buttonsContainer">
          <div className="modalDivider" />
          <IonButton className="maf" fill="clear" onClick={onMarkAsFinished}>
            Yes, mark as finished
          </IonButton>
          <div className="modalDivider" />
          <IonButton id="cancel" fill="clear" onClick={onCancel}>
            Cancel
          </IonButton>
        </div>
      </div>
    </IonModal>
  );
};

export default MarkAsFinishedModal;
