import { Book, Bookmark, Progress, QuizAttempt, QuizQuestionAnswer, User } from "domain/Interfaces";
import { ServerService } from "services/ServerService";
import { useAppDispatch } from "./customReduxHooks";
import { LocalStorageHelper } from "domain/LocalStorageHelper";
import { setProgress } from "features/progress/progressSlice";
import { setBookmarks } from "features/bookmarks/bookmarksSlice";
import { setQuizAttempts } from "features/quizAttempts/quizAttemptsSlice";
import rhBook from "../data/bookContentRH.json";
import ptBook from "../data/bookContentPT.json";

const useLoadAppData = () => {
  const dispatch = useAppDispatch();

  const shuffleAnswers = (array: QuizQuestionAnswer[]) => {
    let currentIndex = array.length,
      randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex != 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }

    return array;
  };

  const load = async (user: User | null) => {
    // get book data
    const remoteBookData = await ServerService.fetchLatestBookData(user);
    const localBookData: Book[] = [rhBook, ptBook];
    let books: Book[] = localBookData;
    if (remoteBookData.length) books = remoteBookData;
    books.forEach(b => b.modules.forEach(m => m.quizQuestions.forEach(q => (q.answers = shuffleAnswers(q.answers)))));

    // get bulletins
    const bulletins = await ServerService.getBulletins(user);

    if (user) {
      // get bookmarks
      let bookmarks: Bookmark[] = [];
      const localBookmarks = await LocalStorageHelper.getBookmarks(user.code);
      const remoteBookmarks = await ServerService.fetchBookmarks(user);
      if (
        (localBookmarks.length && remoteBookmarks.data.length) ||
        (localBookmarks.length && !remoteBookmarks.data.length)
      ) {
        bookmarks = [...localBookmarks];
        // sync in case user used app offline and added bookmarks while offline.
        const res = await ServerService.syncLocalBookmarksWithRemoteDB(localBookmarks, user);
        if (res.length) await LocalStorageHelper.saveBookmarks(res, user.code);
      } else {
        bookmarks = [...remoteBookmarks.data];
        await LocalStorageHelper.saveBookmarks(remoteBookmarks.data, user.code);
      }

      // get quiz attempts
      let quizAttempts: QuizAttempt[] = [];
      const localQuizAttempts = await LocalStorageHelper.getQuizAttempts(user.code);
      const remoteQuizAttempts = await ServerService.fetchQuizAttempts(user);
      if (
        (localQuizAttempts.length && remoteQuizAttempts.data.length) ||
        (localQuizAttempts.length && !remoteQuizAttempts.data.length)
      ) {
        quizAttempts = [...localQuizAttempts];
        // sync in case user used app offline and added quiz attempts while offline.
        const res = await ServerService.syncLocalAttemptsWithRemoteDB(localQuizAttempts, user);
        if (res.length) await LocalStorageHelper.saveQuizAttempts(res, user.code);
      } else {
        quizAttempts = [...remoteQuizAttempts.data];
        await LocalStorageHelper.saveQuizAttempts(remoteQuizAttempts.data, user.code);
      }

      // get progress
      let prog: Progress = { rhFinishedChapters: [], ptFinishedChapters: [] };
      const localProg = await LocalStorageHelper.getProgress(user.code);
      const remoteProg = await ServerService.fetchProgress(user);
      const localProgContainsItems = localProg.rhFinishedChapters.length || localProg.ptFinishedChapters.length;
      const remoteProgContainsItems =
        remoteProg.data.rhFinishedChapters.length || remoteProg.data.ptFinishedChapters.length;
      if ((localProgContainsItems && remoteProgContainsItems) || (localProgContainsItems && !remoteProgContainsItems)) {
        prog = { ...localProg };
        // sync in case user used app offline and made progress while offline.
        if (remoteProg) {
          localProg.rhFinishedChapters = Array.from(
            // remove duplicates
            new Set(localProg.rhFinishedChapters.concat(remoteProg.data.rhFinishedChapters))
          );
          localProg.ptFinishedChapters = Array.from(
            // remove duplicates
            new Set(localProg.ptFinishedChapters.concat(remoteProg.data.ptFinishedChapters))
          );
          await ServerService.updateProgress(user, localProg);
        }
      } else {
        prog = { ...remoteProg.data };
      }

      dispatch(setBookmarks({ bms: bookmarks, userId: user.code }));
      dispatch(setQuizAttempts({ attempts: quizAttempts, userId: user.code }));
      dispatch(setProgress({ progress: prog, user: user }));
    }

    return {
      bookData: books,
      bulletins: bulletins,
    };
  };

  return load;
};

export default useLoadAppData;
