import React, { useContext, useEffect, useRef, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  checkIfChapterIsFinished,
  getBookClassBg,
  getBookClassBgColour,
  getCurrentBookFromBookID,
  getPrevRoute,
} from "utils/Utils";
import {
  decreaseFontSizePercentage,
  increaseFontSizePercentage,
} from "features/fontSizePercentage/fontSizePercentageSlice";
import { BookID, Bookmark, Progress } from "domain/Interfaces";
import { useAppDispatch, useAppSelector } from "hooks/customReduxHooks";
import { useHistory, useLocation, useParams } from "react-router";
import { AppContext } from "domain/AppContextProvider";
import { LocalStorageHelper } from "domain/LocalStorageHelper";
import { addBookmark, removeBookmark } from "features/bookmarks/bookmarksSlice";
import { ServerService } from "services/ServerService";
import { Swiper, SwiperSlide } from "swiper/react";
import { setProgress } from "features/progress/progressSlice";
import SwiperCore, { Navigation } from "swiper";
import ContentsModal from "components/ContentsModal/ContentsModal";
import TextControlsPopover from "components/TextControlsPopover/TextControlsPopover";
import SwiperControls from "components/SwiperControls/SwiperControls";
import CustomBackButton from "components/CustomBackButton/CustomBackButton";
import Lightbox from "react-image-lightbox";
import "swiper/css";
import "swiper/css/navigation";
import "./ChapterContent.css";

// enable navigation for swiper
SwiperCore.use([Navigation]);

const ChapterContent: React.FC<{}> = () => {
  const { appContext, setAppContext } = useContext(AppContext);

  const params = useParams<{ moduleNumber: string }>();

  const dispatch = useAppDispatch();

  const history = useHistory();
  const location = useLocation<{ chapterNumber: string | undefined }>();
  const chapterNumber = location.state?.chapterNumber;

  const popoverRef = useRef<HTMLIonPopoverElement>(null);

  const books = appContext.books;
  const bookID = appContext.bookID;
  const currentBook = getCurrentBookFromBookID(books, bookID);
  const currentModule = currentBook.modules.find(mod => mod.number === parseInt(params.moduleNumber))!;

  const { fontSizePercentage, bookmarks, progress } = useAppSelector(state => state);

  const startingIndex = currentModule.chapters.findIndex(chap => chap.number === parseInt(chapterNumber!));

  const [swiperRef, setSwiperRef] = useState<SwiperCore | null>(null);
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [activeSearchIndex, setActiveSearchIndex] = useState(0);
  const [activeSlideIndex, setActiveSlideIndex] = useState(startingIndex!);
  const [searchOccurences, setSearchOccurences] = useState<HTMLCollectionOf<HTMLElement>>();
  const [textControlsPopoverOpen, setTextControlsPopoverOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageLightbox, setImageLightbox] = useState({
    src: "",
    isOpen: false,
  });

  const currentChapter = currentModule.chapters[activeSlideIndex];
  const isChapterFinished = checkIfChapterIsFinished(
    bookID,
    progress.progress,
    currentModule.number,
    currentChapter.number
  );

  useEffect(() => {
    const imageElements = document.getElementsByTagName("img");
    for (const img of Array.from(imageElements)) {
      img.onclick = () => {
        // console.log("image: ", img.src);
        setImageLightbox({
          src: img.src,
          isOpen: true,
        });
      };
    }
  });

  useEffect(() => {
    setAppContext(prev => ({ ...prev, viewingChapterContent: true }));
    return () => setAppContext(prev => ({ ...prev, viewingChapterContent: false }));
  }, []);

  useEffect(() => {
    const copy = appContext.lastReadChapters;
    const key = bookID === 1 ? "rh" : "pt";
    copy[key] = { module: currentModule.number, chapter: currentChapter.number };
    setAppContext(prev => ({ ...prev, lastReadChapters: copy }));
    LocalStorageHelper.setLastReadChapters(copy);
  }, [activeSlideIndex]);

  let title = `${currentModule.chapters[activeSlideIndex].number} / ${Math.max(
    ...currentModule.chapters.map(chap => chap.number)
  )}`;

  const handleTextControlsClick = (e: any) => {
    popoverRef.current!.event = e;
    setTextControlsPopoverOpen(true);
  };

  const handleBookmarkClick = async () => {
    const index = bookmarks.value.findIndex(
      b => b.bookId === bookID && b.moduleNumber === currentModule.number && b.chapterNumber === currentChapter.number
    );
    const bm: Bookmark = {
      id: index !== -1 ? bookmarks.value[index].id : "",
      bookId: bookID,
      moduleNumber: currentModule.number,
      chapterNumber: currentChapter.number,
    };

    if (index === -1) {
      // add bookmark
      dispatch(addBookmark({ bm: bm, user: appContext.user! }));
    } else {
      // remove bookmark
      dispatch(removeBookmark({ bm: bm, user: appContext.user! }));
    }
  };

  const handleSearchInputChange = (text: string) => {
    // highlight search occurences
    const highlighter = require("@rbuljan/hilite");
    const HL = new highlighter.Hilite(".swiper-slide-active");
    HL.value = text;

    // get matches to search text
    const highlights = document.getElementsByTagName("mark");

    // set active highlight
    if (highlights.length > 0) {
      highlights[0].classList.add("activeHighlight");
      highlights[0].scrollIntoView(false);
    }

    setActiveSearchIndex(0);
    setSearchOccurences(highlights);
    setSearchText(text);
  };

  const handleIncrementActiveSearchIndex = () => {
    if (searchOccurences && activeSearchIndex < searchOccurences.length - 1) {
      searchOccurences[activeSearchIndex].classList.remove("activeHighlight");
      searchOccurences[activeSearchIndex + 1].classList.add("activeHighlight");
      searchOccurences[activeSearchIndex + 1].scrollIntoView(false);
      setActiveSearchIndex(prev => prev + 1);
    }
  };

  const handleDecrementActiveSearchIndex = () => {
    if (searchOccurences && activeSearchIndex > 0) {
      searchOccurences[activeSearchIndex].classList.remove("activeHighlight");
      searchOccurences[activeSearchIndex - 1].classList.add("activeHighlight");
      searchOccurences[activeSearchIndex - 1].scrollIntoView(false);
      setActiveSearchIndex(prev => prev - 1);
    }
  };

  const handleSwiperChange = (index: number) => {
    // reset state variables
    resetSearchState();
    setActiveSlideIndex(index);
  };

  const markChapterAsFinished = () => {
    let newProgress: Progress = {
      rhFinishedChapters: [...progress.progress.rhFinishedChapters],
      ptFinishedChapters: [...progress.progress.ptFinishedChapters],
    };
    const key = currentBook.bookID === 1 ? "rhFinishedChapters" : "ptFinishedChapters";

    newProgress[key].push(`${currentModule.number}-${currentChapter.number}`);
    dispatch(setProgress({ progress: newProgress, user: appContext.user! }));

    ServerService.updateProgress(appContext.user, newProgress);
  };

  const calcFontSize = (text: string) => {
    let fontSize = "";
    switch (text) {
      case "h1":
        fontSize = `${(fontSizePercentage.percentage / 100) * 20}px`;
        break;
      case "h2":
        fontSize = `${(fontSizePercentage.percentage / 100) * 15}px`;
        break;
      case "h3":
        fontSize = `${(fontSizePercentage.percentage / 100) * 13}px`;
        break;
      case "main":
        fontSize = `${(fontSizePercentage.percentage / 100) * 17}px`;
        break;
    }

    return fontSize;
  };

  const resetSearchState = () => {
    setSearchText("");
    setActiveSearchIndex(0);
    setSearchOccurences(undefined);
  };

  const isCurrentChapterBookmarked = () => {
    const index = bookmarks.value.findIndex(
      b => b.bookId === bookID && b.moduleNumber === currentModule.number && b.chapterNumber === currentChapter.number
    );
    return index !== -1;
  };

  const replaceImageSourcesInHtml = (html: string, bookID: BookID, moduleNumber: string): string => {
    let book = "";
    switch (bookID) {
      case 1:
        book = "Road+Haulage";
        break;
      case 2:
        book = "Passenger+Travel";
        break;
    }

    const newHtml = html.replace(
      /src="image\//g,
      `src="https://eos-book.s3.eu-west-1.amazonaws.com/${book}_Module_${moduleNumber}_-`
    );

    return newHtml;
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar
          style={{ "--border-width": showSearchInput ? "0" : "0 0 0.55px" }}
          className={getBookClassBg(bookID)}
        >
          {currentModule.chapters[activeSlideIndex].number !== 0 && module && (
            <IonTitle className="counterTitle">{title}</IonTitle>
          )}
          <IonButtons slot="start">
            <CustomBackButton url={location.pathname} />
          </IonButtons>
          <IonButtons slot="end">
            <IonButton className="textControlsBtn" onClick={handleTextControlsClick}>
              Aa
            </IonButton>
            <IonButton onClick={handleBookmarkClick}>
              <IonIcon
                slot="icon-only"
                icon={`./assets/svgs/Button_Bookmark_${isCurrentChapterBookmarked() ? "Active" : "Default"}.svg`}
              />
            </IonButton>
            <IonButton
              onClick={() =>
                setShowSearchInput(prev => {
                  return !prev;
                })
              }
            >
              <IonIcon slot="icon-only" icon={"./assets/svgs/Button_Page_Search_Default.svg"} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent scrollY={false}>
        {showSearchInput && (
          <div id="searchContainer" className={getBookClassBgColour(bookID)}>
            <IonButton fill="clear" onClick={() => setShowSearchInput(false)}>
              Done
            </IonButton>
            <IonItem lines="none">
              <IonInput
                value={searchText}
                placeholder="Search this page"
                onIonChange={e => handleSearchInputChange(e.detail.value!)}
              />
              <div id="occurencesContainer">
                {searchOccurences && searchOccurences?.length > 0 ? activeSearchIndex + 1 : 0} of{" "}
                {searchOccurences ? searchOccurences.length : 0}
              </div>
            </IonItem>
            <div id="arrowBtnContainer">
              <IonButton className="arrowBtn" fill="clear" onClick={handleIncrementActiveSearchIndex}>
                <IonIcon slot="icon-only" icon={"./assets/svgs/Button_Disclosure_Down.svg"} />
              </IonButton>
              <IonButton className="arrowBtn" fill="clear" onClick={handleDecrementActiveSearchIndex}>
                <IonIcon slot="icon-only" icon={"./assets/svgs/Button_Disclosure_Up.svg"} />
              </IonButton>
            </div>
          </div>
        )}
        <Swiper
          // followFinger={false}
          // touchRatio={0.1}
          onInit={swiper => setSwiperRef(swiper)}
          // navigation={{ prevEl: ".swiperControlPrev", nextEl: ".swiperControlNext" }}
          id="chapterContentSwiper"
          onSlideChange={swiper => handleSwiperChange(swiper.activeIndex)}
          initialSlide={startingIndex}
          allowTouchMove={false}
        >
          {currentModule.chapters.map((chap, i) => (
            <SwiperSlide key={i} id="slide" style={{ userSelect: "none" }}>
              <div className="swiperInnerWrapper">
                <h3 style={{ fontSize: calcFontSize("h3") }}>Module {currentModule.number}</h3>
                <h2 style={{ fontSize: calcFontSize("h2") }}>{currentModule.name}</h2>
                <div id="divider" />
                <h1 style={{ fontSize: calcFontSize("h1") }}>{`${chap.number === 0 ? "" : `${chap.number}.`} ${
                  chap.name
                }`}</h1>
                {chap.sections.map((section, index) => {
                  return (
                    <div key={index}>
                      <div style={{ userSelect: "none" }}>
                        {section.name && (
                          <h1
                            style={{ fontSize: calcFontSize("h1") }}
                          >{`${chap.number}.${section.number} ${section.name}`}</h1>
                        )}
                        <div
                          className="htmlContainer"
                          style={{ fontSize: calcFontSize("main") }}
                          dangerouslySetInnerHTML={{
                            __html: replaceImageSourcesInHtml(section.html, bookID, params.moduleNumber),
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
                <div id="chapterContentButtonsContainer">
                  <IonLabel>Finished reading this chapter?</IonLabel>
                  <IonButton
                    disabled={isChapterFinished}
                    onClick={() => markChapterAsFinished()}
                    style={{
                      "--background": isChapterFinished ? "var(--status-finished)" : "var(--reg-button)",
                    }}
                  >
                    {isChapterFinished && <IonIcon icon="./assets/svgs/Button_Answer_Tick.svg" />}
                    {isChapterFinished ? "Finished" : "Mark as finished"}
                  </IonButton>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <SwiperControls
          swiperRef={swiperRef}
          showPrev={currentModule.chapters[activeSlideIndex - 1] !== undefined}
          showNext={currentModule.chapters[activeSlideIndex + 1] !== undefined}
          finishButton={
            currentModule.chapters[activeSlideIndex + 1] === undefined
              ? { handler: () => history.replace(getPrevRoute(location.pathname)) }
              : undefined
          }
          middleButton={{ text: "Contents", handler: () => setIsModalOpen(true), disabled: false }}
        />
        <ContentsModal
          module={currentModule}
          isOpen={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
          moveToSlide={index => {
            setIsModalOpen(false);
            swiperRef?.slideTo(index);
          }}
        />
        {imageLightbox.isOpen && (
          <Lightbox
            wrapperClassName="x"
            reactModalStyle={{ content: { top: "env(safe-area-inset-top)" } }}
            mainSrc={imageLightbox.src}
            onCloseRequest={() => setImageLightbox({ src: "", isOpen: false })}
          />
        )}
        <TextControlsPopover
          isOpen={textControlsPopoverOpen}
          popoverRef={popoverRef}
          handleFontSizeDecrease={() => dispatch(decreaseFontSizePercentage())}
          handleFontSizeIncrease={() => dispatch(increaseFontSizePercentage())}
          handleDismiss={() => setTextControlsPopoverOpen(false)}
        />
      </IonContent>
    </IonPage>
  );
};

export default ChapterContent;
